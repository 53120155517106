// Chakra Imports
import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Flex,
  Link,
  Text,
  useBreakpointValue,
  useColorModeValue
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import theme from "theme/theme";
import ForumNavbarLinks from "./ForumNavbarLinks";

const server= "https://23.20.212.243:8080/api/";
// const server= "http://ec2-34-201-174-68.compute-1.amazonaws.com:3000/api/";



export default function ForumNavbar(props) {
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", changeNavbar);

    return () => {
      window.removeEventListener("scroll", changeNavbar);
    }
  })

  const {
    variant,
    children,
    fixed,
    secondary,
    brandText,
    onOpen,
    ...rest
  } = props;

  // Here are all the props that may change depending on navbar's type or state.(secondary, variant, scrolled)

  let mainText = (fixed && scrolled) ? useColorModeValue("gray.700", "gray.200") : useColorModeValue("white", "gray.200");
  let secondaryText = (fixed && scrolled) ? useColorModeValue("gray.700", "gray.200") : useColorModeValue("white", "gray.200");
  let navbarPosition = "relative";
  let navbarFilter = "none";
  let navbarBackdrop = "none";
  let navbarShadow = "none";
  let navbarBg = "none";
  let navbarBorder = "transparent";
  let secondaryMargin = "0px";
  let paddingX = "15px";
  if (props.fixed === true)
    if (scrolled === true) {
      navbarPosition = "fixed";
      navbarShadow = useColorModeValue(
        "0px 7px 23px rgba(0, 0, 0, 0.05)",
        "none"
      );
      navbarBg = useColorModeValue(
        "linear-gradient(112.83deg, rgba(255, 255, 255, 0.82) 0%, rgba(255, 255, 255, 0.8) 110.84%)",
        "linear-gradient(112.83deg, rgba(255, 255, 255, 0.21) 0%, rgba(255, 255, 255, 0) 110.84%)"
      );
      navbarBorder = useColorModeValue("#FFFFFF", "rgba(255, 255, 255, 0.31)");
      navbarFilter = useColorModeValue(
        "none",
        "drop-shadow(0px 7px 23px rgba(0, 0, 0, 0.05))"
      );
    }
  if (props.secondary) {
    navbarBackdrop = "none";
    navbarPosition = "relative";
    mainText = "white";
    secondaryText = "white";
    secondaryMargin = "22px";
    paddingX = "30px";
  }
  const changeNavbar = () => {
    if (window.scrollY > 1) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  };

  const isDesktop= useBreakpointValue({base:false, lg: true })

  return (
    <>
      <Flex
        boxShadow={navbarShadow}
        bg={navbarBg}
        borderColor={navbarBorder}
        filter={navbarFilter}
        backdropFilter={navbarBackdrop}
        borderWidth="1.5px"
        borderStyle="solid"
        transitionDelay="0s, 0s, 0s, 0s"
        transitionDuration=" 0.25s, 0.25s, 0.25s, 0s"
        transition-property="box-shadow, background-color, filter, border"
        transitionTimingFunction="linear, linear, linear, linear"
        alignItems={{ xl: "center" }}
        display="flex"
        justifyContent={{ xl: "center" }}
        lineHeight="25.6px"
        mx="auto"
        position='fixed'
        top="0"
        background='rgb(58, 114, 104, 0.8)'
        zIndex="5"
        px={{
          sm: paddingX,
          md: "30px",
        }}
        ps={{
          xl: "12px",
        }}
        pt="8px"
        pb="15px"
        w={{ sm: "calc(100vw - 30px)", xl: "100%" }}
      >

        <Flex
          w="100%"
          flexDirection={{
            sm: "column",
            md: "row",
          }}
          justify="center"
          alignItems={{ xl: "center" }}
        >
          <Box mt="7px" fontFamily={theme.fonts.button} fontWeight="600" fontSize="md" ml="10">
            Recruiter Community

          </Box>
          <Box ms="auto" w={{ sm: "100%", md: "unset" }}>

            <ForumNavbarLinks
              onOpen={props.onOpen}
              logoText={props.logoText}
              secondary={props.secondary}
              fixed={props.fixed}
              scrolled={scrolled}
            />
          </Box>

        </Flex>
        <Box>
        </Box>
      </Flex>

    </>
  );
}