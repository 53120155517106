// Chakra imports
import React, { useState } from "react";
import "../../assets/css/signup.css"

import {
  Grid,
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  Icon,
  Input,
  Link,
  Switch,
  Text,
  useColorModeValue,
  LightMode,
  FormErrorMessage
} from "@chakra-ui/react";
// Assets
import BgSignUp from "assets/img/BgSignUp.png";

import { FaLinkedin, FaFacebook, FaGoogle } from "react-icons/fa";
import axios from 'axios';
import { useHistory } from 'react-router-dom';

import { useForm } from 'react-hook-form';


import { notification } from 'antd';
import theme from "theme/theme";

function SignUp() {
  const bgForm = useColorModeValue("white", "navy.800");
  const titleColor = useColorModeValue("gray.700", "blue.500");
  const textColor = useColorModeValue("gray.700", "white");
  const colorIcons = useColorModeValue("gray.700", "white");
  const bgIcons = useColorModeValue("trasnparent", "navy.700");
  const bgIconsHover = useColorModeValue("gray.50", "whiteAlpha.100");

  const server= "https://23.20.212.243:8080/api/";
  //  const server= "http://ec2-34-201-174-68.compute-1.amazonaws.com:3000/api/";

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [cname, setCname] = useState('');
  const [fname, setFname] = useState('');
  const [lname, setLname] = useState('');

  const history = useHistory();




  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting },
  } = useForm()



  function onSignup(values) {

    return new Promise((resolve) => {
      axios.post(`${server}user/register`, {
        "email": email,
        "firstName": fname,
        "lastName": lname,
        "companyName": cname,
        "password": password
      })
        .then((result) => {


          if (result.data.status === true) {

            notification.success({
              top: '10%',
              placement: 'topRight',
              message: result.data.message,

              onClose: () => {

                history.push("/auth/home");

              },
            });
          } else {
            notification.error({
              top: '10%',
              placement: 'topRight',
              message: result.data.message,
            });
          }
        })
        .catch(function (error) {
          if (error.code === "ERR_NETWORK") {
            notification.error({
              top: '10%',
              placement: 'topRight',
              message: result.data.message,
            });
          } else {
            const msg = JSON.parse(error.request.response);
            notification.error({
              top: '10%',
              placement: 'topRight',
              message: msg.message,
            });
          }
        });


    })
  }

  return (
    <>
      <Flex alignItems='center' justifyContent='center' className="bg-img" >
        <Flex minH={{ md: "96vh" }} 
          h={{ sm: "initial", md: "75vh", lg: "85vh" }}
          w='100%'
          maxW='1044px'
          mx='auto'
          justifyContent='space-between'
          mb='30px'
          pt={{ md: "0px" }}>
          <Flex
            w='100%'
            h='100%'
            align='center'
            justify='center'
            mb='60px'
            mt={{ base: "50px", md: "20px" }}>
            <Flex zIndex='2'
              direction='column'
              w='445px'
              gap="15px"
              background='transparent'
              borderRadius='15px'
              p='40px'
              mx={{ base: "100px" }}
              m={{ base: "20px", md: "auto" }}
              bg={bgForm}
              boxShadow={useColorModeValue(
                "0px 5px 14px rgba(0, 0, 0, 0.05)",
                "unset"
              )}>
              <Text
                fontSize='xl'
                color={textColor}
                textAlign='center'
                fontFamily={theme.fonts.userDetails} 
                >
                Register With
              </Text>
              <HStack spacing='15px' justify='center' align="center">
                <Flex
                  justify='center'
                  align='center'
                >
                  <Link display="flex" justifyContent="center" alignItems="center" href='#'>
                    <Icon as={FaFacebook} color={"gray.600"} w='40px' h='40px' _hover={{ color: "blue.50", bg: "blue.500" }} borderRadius='8px'
                      border={useColorModeValue("1px solid", "0px")}
                      borderColor='gray.200'
                      cursor='pointer'
                      transition='all .25s ease' p="2"
                    />
                  </Link>
                </Flex>
                <Flex
                  justify='center'
                  align='center'
                >
                  <Link display="flex" justifyContent="center" alignItems="center" href='#'>
                    <Icon
                      as={FaLinkedin}
                      color={colorIcons}
                      w='40px' h='40px' _hover={{ color: "blue.50", bg: "blue.700" }} borderRadius='8px'
                      border={useColorModeValue("1px solid", "0px")}
                      borderColor='gray.200'
                      cursor='pointer'
                      transition='all .25s ease' p="2"
                    />
                  </Link>
                </Flex>
                <Flex
                  justify='center'
                  align='center'
                >
                  <Link display="flex" justifyContent="center" alignItems="center" href='#'>
                    <Icon
                      as={FaGoogle}
                      color={colorIcons}
                      w='40px' h='40px' _hover={{ color: "orange.50", bg: "orange.500" }} borderRadius='8px'
                      border={useColorModeValue("1px solid", "0px")}
                      borderColor='gray.200'
                      cursor='pointer'
                      transition='all .25s ease' p="2"
                    />
                  </Link>
                </Flex>
              </HStack>
              <Text
                fontSize='lg'
                color='gray.400'
                fontWeight='bold'
                textAlign='center'
              >
                or
              </Text>
              
              <form onSubmit={handleSubmit(onSignup)}>

                <FormControl isInvalid={errors.email} mt={4}>
                  <FormLabel htmlFor='email' fontFamily={theme.fonts.userAbout} fontSize="sm" fontWeight={"bold"}>Email</FormLabel>
                  <Input
                    type="text"
                    id='email'
                    placeholder='Your Email Address' fontFamily={theme.fonts.message} fontSize="sm"

                    {...register('email', {
                      required: 'Email is required',

                    })}
                    onChange={event => setEmail(event.currentTarget.value)}
                  />
                  <FormErrorMessage>
                    {errors.email && errors.email.message}
                  </FormErrorMessage>
                </FormControl>
                
                <FormControl isInvalid={errors.password} mt={4}>
                  <FormLabel htmlFor='password' fontFamily={theme.fonts.userAbout} fontSize="sm" fontWeight={"bold"}>Password</FormLabel>
                  <Input
                    type="password"
                    id='password'
                    placeholder='Your PassWord' fontFamily={theme.fonts.message} fontSize="sm"

                    {...register('password', {
                      required: 'password is required',

                    })}
                    onChange={event => setPassword(event.currentTarget.value)}
                  />
                  <FormErrorMessage>
                    {errors.password && errors.password.message}
                  </FormErrorMessage>
                </FormControl>
                <Grid templateColumns="repeat(2, 1fr)" gap={3}>
                  <Box  >


                    <FormControl isInvalid={errors.fname} mt={4}>
                      <FormLabel htmlFor='fname' fontFamily={theme.fonts.userAbout} fontSize="sm" fontWeight={"bold"}>First Name</FormLabel>
                      <Input
                        type="text"
                        id='fname'
                        placeholder='Your First Name' fontFamily={theme.fonts.message} fontSize="sm"

                        {...register('fname', {
                          required: 'First Name is required',

                        })}
                        onChange={event => setFname(event.currentTarget.value)}
                      />
                      <FormErrorMessage>
                        {errors.fname && errors.fname.message}
                      </FormErrorMessage>
                    </FormControl>
                  </Box>

                  <Box  >

                    <FormControl isInvalid={errors.lname} mt={4}>
                      <FormLabel htmlFor='lname' fontFamily={theme.fonts.userAbout} fontSize="sm" fontWeight={"bold"}>Last Name</FormLabel>
                      <Input
                        type="text"
                        id='lname'
                        placeholder='Your Last Name' fontFamily={theme.fonts.message} fontSize="sm"

                        {...register('lname', {
                          required: 'Last Name is required',

                        })}
                        onChange={event => setLname(event.currentTarget.value)}
                      />
                      <FormErrorMessage>
                        {errors.lname && errors.lname.message}
                      </FormErrorMessage>
                    </FormControl>
                  </Box>
                </Grid>


                <FormControl isInvalid={errors.cname} mt={4}>
                  <FormLabel htmlFor='cname' fontFamily={theme.fonts.userAbout} fontSize="sm" fontWeight={"bold"}>Company Name</FormLabel>
                  <Input
                    type="text"
                    id='cname'
                    placeholder='Your Company Name' fontFamily={theme.fonts.message} fontSize="sm"

                    {...register('cname', {
                      required: 'Company Name is required',

                    })}
                    onChange={event => setCname(event.currentTarget.value)}
                  />
                  <FormErrorMessage>
                    {errors.cname && errors.cname.message}
                  </FormErrorMessage>
                </FormControl>

                <Flex
                  flexDirection='column'
                  justifyContent='center'
                  alignItems='center'
                  maxW='100%'
                  mt={4}>
                  <Button color="white" borderRadius="30px" backgroundColor="gray.600" mx='6px' px="8" fontFamily={theme.fonts.button} fontSize="sm" isLoading={isSubmitting}  type='submit'> Sign Up  </Button>
                </Flex>

              </form>
              <Flex
                flexDirection='column'
                justifyContent='center'
                alignItems='center'
                maxW='100%'
                mt='0px'>
                <Text color={textColor} fontWeight='medium' fontFamily={theme.fonts.message} fontSize="md">
                  Already have an account?
                  <Link
                    color="orange.500"
                    ms='5px'
                    href='#/auth/signin'
                    fontWeight='bold'
                  >
                    Sign In
                  </Link>
                </Text>
              </Flex>
            </Flex>
          </Flex>
        </Flex>
            <Box
              overflowX='hidden'
              h='100%'
              w='100%'
              left='0px'
              position='absolute'
              >
              <Box
                w='100%'
                h='100%'
                bgSize='cover'
                bg='gray.600'
                opacity='0.7'></Box>
            </Box>
      </Flex>
    </>
  );
}

export default SignUp;
