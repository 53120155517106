import React, { Component, useEffect, useState, useRef, useReducer } from 'react';
import {
  useDisclosure,
  Avatar,
  Stack,
  Switch,
  Badge,
  Button,
  Flex,
  Table,
  Tbody,
  Text,
  Th,
  Thead,
  Tr,
  Td,
  useColorModeValue,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  FormControl,
  FormLabel,
  Input,
  Textarea,
  Grid,
  Box,
  HStack,
  FormErrorMessage

} from "@chakra-ui/react";
import { FaEdit, FaUserCircle, FaTrashAlt } from "react-icons/fa";
// Custom components
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import axios from 'axios';
import { notification } from 'antd';
import moment from "moment";
import { MDBDataTable } from 'mdbreact';
import { useForm } from 'react-hook-form';
import theme from 'theme/theme';


function Tables() {


  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting },
  } = useForm()
  const textColor = useColorModeValue("gray.700", "white");
  const borderColor = useColorModeValue("gray.200", "gray.600");
  const server= sessionStorage.getItem("server");
  const token = sessionStorage.getItem("token");


  const [questionsData, setQuestionsData] = useState([]);
  const [edit, setEdit] = useState([]);
  const [comment, setComment] = useState([]);
  const [commentEdit, setCommentEdit] = useState([]);


  const [description, setDescription] = useState();

  const { isOpen, onOpen, onClose } = useDisclosure();

  const [reducerValue, forceUpdate] = useReducer(x => x + 1, 0);
  const [size, setSize] = React.useState('xl')


  const QuestionsForm = useRef(null)
  const Comment = useDisclosure()
  const Description = useDisclosure()

  const Edit = useDisclosure()
  const initialRef = React.useRef(null)
  const finalRef = React.useRef(null)

  const DeleteCommentRef = useRef(null)

  const DeleteComment = useDisclosure()


  const QuestionsEdit = () => {
    const form = QuestionsForm.current


    var data = JSON.stringify({
      "id": edit.id,
      "title": `${form['title'].value}`,
      "description": `${form['description'].value}`
    });

    var config = {
      method: 'post',
      url: `${server}question/editQuestions`,
      headers: {
        'Authorization': token,
        'Content-Type': 'application/json'
      },
      data: data
    };

    axios(config)
      .then(function (result) {

        if (result.data.status === true) {
          Edit.onClose();
          notification.success({
            top: '10%',
            placement: 'topRight',
            message: result.data.message,
            onClose: () => {
              forceUpdate();
            },
          });
        } else {
          notification.error({
            top: '10%',
            placement: 'topRight',
            message: result.data.message,
          });
        }
      })
      .catch(function (error) {
        if (error.code === "ERR_NETWORK") {
          notification.error({
            top: '10%',
            placement: 'topRight',
            message: result.data.message,
          });
        } else {
          const msg = JSON.parse(error.request.response);
          notification.error({
            top: '10%',
            placement: 'topRight',
            message: msg.message,
          });
        }
      });

  }


  useEffect(() => {

    const fetchData = async () => {

      var data = JSON.stringify({});
      var config = {
        method: 'post',
        url: `${server}admin/viewAllQuestions`,
        headers: {
          'Authorization': token,
          'Content-Type': 'application/json'
        },
        data: data
      };

      axios(config)
        .then(function (response) {

          setQuestionsData(response.data.data);
        })
        .catch(function (error) {
          console.log(error);
        });
    }
    fetchData()

  }, [reducerValue])


  function Questionstatus(id) {

    var data = JSON.stringify({ id: id });
    var config = {
      method: 'post',
      url: `${server}admin/changeQuestionActivity`,
      headers: {
        'Authorization': token,
        'Content-Type': 'application/json'
      },
      data: data
    };

    axios(config)
      .then(function (response) {

        forceUpdate();
      })
      .catch(function (error) {
        console.log(error);
      });
  }



  function Commentstatus(id) {

    var data = JSON.stringify({ id: id });
    var config = {
      method: 'post',
      url: `${server}admin/changeCommentStatus`,
      headers: {
        'Authorization': token,
        'Content-Type': 'application/json'
      },
      data: data
    };

    axios(config)
      .then(function (response) {

        forceUpdate();
      })
      .catch(function (error) {
        console.log(error);
      });
  }





  function CommentDelete(id) {


    var data = JSON.stringify({
      id: id
    });
    var config = {
      method: 'post',
      url: `${server}admin/deleteComment`,
      headers: {
        'Authorization': token,
        'Content-Type': 'application/json'
      },
      data: data

    };

    axios(config)
      .then(function (response) {

        forceUpdate();
        DeleteComment.onClose();

        if (response.data.status === true) {

          var list = [];
          comment.filter(item => item.id !== id).map((row, index) => { return (list.push(row)) });

          setComment(list);

        }

      })

      .catch(function (error) {
        console.log(error);
      });
      
  }

  const [usersForRender, setUsersForRender] = useState([]);


  useEffect(() => {
    let postsArray = JSON.parse(JSON.stringify(questionsData));
    let questionsData1 = [];
    postsArray.map((item, index) => {
      const { id, title, description, userId, likes_count, comments, createdAt, isActive } = item;

      item.id = (
        <Text fontSize="sm" color={textColor} fontWeight="normal" >  {index + 1}   </Text>
      );
      item.title = (
        <Text fontSize="sm" color={textColor} fontWeight="normal" fontFamily={theme.fonts.userAbout} >  {title} </Text>
      );

      item.description = (
        <Text fontSize="sm" color={textColor} fontWeight="normal" fontFamily={theme.fonts.userAbout} >      {description.length > 50 ?
          <>
            {description.substring(0, 50)}......<a onClick={() => { Description.onOpen(); setDescription(description); }} style={{ color: 'blue', cursor: 'pointer' }}>Readmore</a>
          </>
          :
          <>

            {description}
          </>
        } </Text>

      );
      item.userId = (
        <Text fontSize="sm" color={textColor} fontWeight="normal" fontFamily={theme.fonts.userAbout} > {userId} </Text>
      );

      item.status = (
        <Stack align="center" direction="row"> <Switch size="md" defaultChecked={isActive === true ? true : false} onChange={() => { status(id); }} /> </Stack>

      );
      item.likes_count = (
        <Text fontSize="sm" color={textColor} fontWeight="normal"> {likes_count}</Text>
      );
      item.comments = (
        <Text fontSize="sm" color={textColor} fontWeight="normal">  <Button
          onClick={() => { Comment.onOpen(); setComment(comments); }}>{comments.length}</Button> </Text>
      );

      item.created = (
        <Text fontSize="sm" color={textColor} fontWeight="normal">     {moment(createdAt).format("DD-MM-YYYY hh:mm")}   </Text>
      );

      item.edit = (

        <Button colorScheme='twitter' size="sm" variant='outline' leftIcon={<FaEdit />}  onClick={() => {
          Edit.onOpen(); setEdit(
            {
              "id": id,
              "title": title,
              "description": description
            }
          );
        }}>Edit</Button>


      );

      item.action = (
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div
            className="uil-trash-alt"
            style={{
              cursor: "pointer",
              color: "black",
              fontSize: ".7em",
              padding: ".5rem",
              borderRadius: ".3rem",
              background: "#fb6262",
            }}
            onClick={() => deletePost(posts[index].id)}
          >
            Delete
          </div>
        </div>
      );
      questionsData1.push(item);
    });
    setUsersForRender(questionsData1);
  }, [questionsData]);



  const data = {
    columns: [
      {
        label: "S.No",
        field: "id",
        sort: "asc",
        width: 150,
      },
      {
        label: "Title",
        field: "title",
        sort: "asc",
        width: 270,
      },

      {
        label: "Description",
        field: "description",
        sort: "asc",
        width: 200,
      },

      {
        label: "User",
        field: "userId",
        sort: "asc",
        width: 200,
      },

      {
        label: "Status",
        field: "status",
        sort: "asc",
        width: 200,
      },

      {
        label: "Likes",
        field: "likes_count",
        sort: "asc",
        width: 200,
      },
      {
        label: "Comments",
        field: "comments",
        sort: "asc",
        width: 200,
      },
      {
        label: "Created",
        field: "created",
        sort: "asc",
        width: 200,
      },
      {
        label: "Edit",
        field: "edit",
        sort: "asc",
        width: 200,
      },
    ],
    rows: usersForRender
  };
  return (
    <>
      <Flex direction="column" pt={{ base: "120px", md: "75px" }}>
        <Card overflowX="scroll" pb="0px">
          <CardHeader p="6px 0px 22px 0px">
            <Text fontSize="xl" color={textColor} fontWeight="light" fontFamily={theme.fonts.userDetails}> Questions List  </Text>
          </CardHeader>
          <CardBody fontFamily={theme.fonts.message} fontSize="15px">
            { /**  <Table variant="simple" color={textColor}>
              <Thead>
                <Tr my=".8rem" pl="0px" color="gray.400" >
                <Th pl="0px" borderColor={borderColor} color="gray.400" > S.NO </Th> 
                  <Th pl="0px" borderColor={borderColor} color="gray.400" > Title </Th> 
                  <Th borderColor={borderColor} color="gray.400" >Description</Th>
                  <Th borderColor={borderColor} color="gray.400" >User</Th>
                  <Th borderColor={borderColor} color="gray.400" >Status</Th>
                  <Th borderColor={borderColor} color="gray.400" >Likes</Th>
                  <Th borderColor={borderColor} color="gray.400" >Comments</Th>
                   <Th borderColor={borderColor} color="gray.400" >Created</Th>
                  <Th borderColor={borderColor}> Edit</Th>
                </Tr>
              </Thead>
              <Tbody>
                {questionsData.map((row, index, arr) => {
                      const { id, title, description, userId, createdAt, likes_count, comments, isActive } = row;
                      const textColor = useColorModeValue("gray.500", "white");
                      const titleColor = useColorModeValue("gray.700", "white");
                      const bgStatus = useColorModeValue("gray.400", "navy.900");
                      const borderColor = useColorModeValue("gray.200", "gray.600");
                  return (
                    <Tr>
                           <Td borderColor={borderColor}  >
                     
                     <Text fontSize="sm" color={textColor} fontWeight="normal" >
                       {index+1}
                     </Text> 
                  
                 </Td>
                    <Td
                      minWidth={{ sm: "250px" }}
                      pl="0px"
                      borderColor={borderColor}
                      
                    >
                      <Text fontSize="sm" color={textColor} fontWeight="normal" >
                          {title}
                        </Text> 
                    </Td>
              
                    <Td borderColor={borderColor}  >
                     
                        <Text fontSize="sm" color={textColor} fontWeight="normal" >
                          

                          { description.length > 50 ? 
<>
{description.substring(0, 50)}......<a onClick={() => {Description.onOpen(); setDescription(description);}} style={{color:'skyblue',cursor:'pointer'}}>Readmore</a>
</>
                            :
                            <>
                             
                            {description}
                            </>
                          }
 
                       </Text>                      
                    </Td>

                    <Td borderColor={borderColor}  >
                     
                     <Text fontSize="sm" color={textColor} fontWeight="normal" >
                       {userId}
                     </Text> 
                  
                 </Td> 

                  
                    <Td borderColor={borderColor}  > 

                        <Stack align="center" direction="row">
                        
  <Switch size="md"  defaultChecked={isActive === true ? true : false}  onChange={() => { Questionstatus(id);  }}  />
 
</Stack>
                      
                    </Td>

                    <Td borderColor={borderColor}  >
                    <Text fontSize="sm" color={textColor}  fontWeight="normal">
                        {likes_count}
                      </Text>
                    </Td>
                    <Td borderColor={borderColor}  >
                    <Text fontSize="sm" color={textColor}  fontWeight="normal">
                        <Button
                          onClick={() => {Comment.onOpen(); setComment(comments);}}>{comments.length}</Button>
                      </Text>
                    </Td>
                    
                    <Td borderColor={borderColor}  >
                    <Text fontSize="sm" color={textColor}  fontWeight="normal">
                        
                        {moment(createdAt).format("DD-MM-YYYY hh:mm")}

                      </Text>
                    </Td> 
                    <Td borderColor={borderColor}  >

                    <Button colorScheme='twitter' variant='outline' leftIcon={<FaEdit />} onClick={() => {Edit.onOpen(); setEdit(
                        {
                            "id": id,
                            "title": title,
                            "description": description
                        }
                    );}}>Edit</Button>


                  
                    </Td>
                  </Tr>
                  
                  );
                })}
              </Tbody>
              </Table> **/}
            <MDBDataTable   
              className='usertable'
              data={data}
              searching={true}
              paging={true}
              info={true}
            />
          </CardBody>
        </Card>

      </Flex>


      <Modal size="4xl" isOpen={Edit.isOpen} onClose={Edit.onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader fontWeight='bold' fontFamily={theme.fonts.userDetails}>Questions Edit</ModalHeader>
          <ModalCloseButton />
          <form ref={QuestionsForm} onSubmit={handleSubmit(QuestionsEdit)}>
            <ModalBody pb={6}>

              <FormControl isInvalid={errors.title}>
                <FormLabel htmlFor='title' fontFamily={theme.fonts.userAbout} fontWeight="bold">Title</FormLabel>
                <Input fontFamily={theme.fonts.message} fontSize="sm"
                  variant='auth'
                  ms='4px'
                  type='text'
                  placeholder='Entered Title'
                  mb='24px'
                  size='lg'
                  defaultValue={edit.title}
                  name='title'
                  {...register('title', {
                    required: 'Title is required',

                  })}
                />
                <FormErrorMessage fontFamily={theme.fonts.message}>
                  {errors.title && errors.title.message}
                </FormErrorMessage>
              </FormControl>


              {/* <Grid templateColumns="repeat(1, 1fr)" gap={3}>
                <FormLabel ms='4px' fontSize='sm' fontWeight='normal'>Title </FormLabel>
              </Grid> */}

              <Grid templateColumns="repeat(1, 1fr)" gap={3}>
                <FormLabel ms='4px' fontSize='sm' fontWeight='bold' fontFamily={theme.fonts.userAbout} >Description</FormLabel>

                <Textarea name='description' placeholder='Entered Description' defaultValue={edit.description} fontFamily={theme.fonts.message} fontSize="sm"></Textarea>

              </Grid>


            </ModalBody>
            <ModalFooter>
              <Button colorScheme="blue" mr={2} isLoading={isSubmitting} onClick={QuestionsEdit} fontFamily={theme.fonts.button} fontSize="sm"> Save    </Button>
            </ModalFooter>

          </form>
        </ModalContent>

      </Modal>



      <Modal
        initialFocusRef={initialRef}
        finalFocusRef={finalRef}
        isOpen={Comment.isOpen}
        onClose={Comment.onClose}
        size="4xl"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader fontFamily={theme.fonts.userDetails}>Comments</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>

            <FormControl>

              {comment.map((row, index, arr) => {
                return (
                  <>

                    <HStack spacing={2}>
                      <FaUserCircle className='profile' />
                      <span style={{ fontWeight: 'bold', color:"#EA8608",textTransform:"uppercase" }}>{row.user.firstName}</span> <span> {moment(row.createdAt).format("DD-MM-YYYY hh:mm")} </span>
                      <Stack align="center" direction="row">

                        <Switch size="md" defaultChecked={row.isActive === true ? true : false} onChange={() => { Commentstatus(row.id); }} />

                      </Stack>
                      <FaTrashAlt style={{ color: 'red', cursor: 'pointer' }} onClick={(e) => { DeleteComment.onOpen(); setCommentEdit(row) }} />
                    </HStack>

                    <p style={{ marginBottom: '2%', paddingLeft: '30px' }}>    <Text fontSize='md' color='gray' me='10px' fontFamily={theme.fonts.message}>   {row.comment}</Text> </p>

                  </>
                );
              })}
            </FormControl>
          </ModalBody>



        </ModalContent>
      </Modal>


      <Modal
        initialFocusRef={initialRef}
        finalFocusRef={finalRef}
        isOpen={Description.isOpen}
        onClose={Description.onClose}
        size="2xl"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader fontFamily={theme.fonts.userDetails}>Description</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>

            <FormControl fontFamily={theme.fonts.message} fontSize="sm">
              {description}
            </FormControl>
          </ModalBody>

        </ModalContent>
      </Modal>


      <Modal
        size="2xl"
        initialFocusRef={DeleteCommentRef}
        finalFocusRef={DeleteCommentRef}
        isOpen={DeleteComment.isOpen}
        onClose={DeleteComment.onClose}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader fontFamily={theme.fonts.userDetails}> Delete Comment</ModalHeader>
          <ModalCloseButton />

          <ModalBody pb={6} fontFamily={theme.fonts.message}>
            <form ref={DeleteComment}>

              <p>Are you sure want to delete?  </p>

              <Stack direction='row' spacing={4} style={{ justifyContent: 'end' }}>
                <Button onClick={(e) => { CommentDelete(commentEdit.id) }} colorScheme='red' variant='solid' fontFamily={theme.fonts.button}> Yes </Button>
                <Button onClick={(e) => { DeleteComment.onClose() }} colorScheme='gray' variant='outline' fontFamily={theme.fonts.button}>  No </Button>
              </Stack>

            </form>
          </ModalBody>
        </ModalContent>
      </Modal>

    </>
  );
}

export default Tables;
