// Chakra imports
import React, { useEffect, useRef, useState, useReducer } from 'react';

import {

  Box,
  Button,
  Flex,
  Grid,
  GridItem,
  Icon,
  Image,
  Link,
  Switch,
  Text,
  useColorMode,
  useColorModeValue,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Input,
  Textarea,
  FormLabel,
  chakra,
  Container,
  Stack,
  Show,
  color,
  SimpleGrid,
  FormControl,
  Divider,
  VStack,
  HStack,
  Heading,
  Badge,
  Avatar, AvatarBadge,

} from "@chakra-ui/react";
// Assets

// Custom components
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import {
  FaCube,
  FaPenFancy,
  FaPlus,
  FaArrowCircleLeft,
  FaUserCircle,
  FaInstagram,
  FaTwitter,
  FaYoutube,
  FaFacebook,
  FaUserPlus,
  FaUserCheck,
  FaEdit
} from "react-icons/fa";
import { Tabs, TabList, TabPanels, Tab, TabPanel } from '@chakra-ui/react'

import axios from 'axios';
import { useHistory } from 'react-router-dom';
import moment from "moment";
import back from "../../assets/img/back1.jpg"
import back1 from "./2.png"
import theme from 'theme/theme';

function Profile() {
  const { colorMode } = useColorMode();
  const [show, setShow] = useState(false);



  // Chakra color mode
  const textColor = useColorModeValue("gray.700", "white");
  const textColorAbout = useColorModeValue("blue.400", "white");
  const iconColor = useColorModeValue("blue.500", "white");
  const bgProfile = useColorModeValue("hsla(0,0%,100%,.8)", "navy.800");
  const borderProfileColor = useColorModeValue("white", "transparent");
  const emailColor = useColorModeValue("gray.400", "gray.300");


  const [profileData, setProfileData] = useState([]);
  const [Followings, setFollowings] = useState("");
  const [Followers, setFollowers] = useState("");

  const [Followhide, setFollowhide] = useState(false);
  const [questionsData, setQuestionsData] = useState([]);

  const server= "https://23.20.212.243:8080/api/";
  // const server= "http://ec2-34-201-174-68.compute-1.amazonaws.com:3000/api/";

  const token = sessionStorage.getItem("token");
  const id = sessionStorage.getItem("userId");



  const [reducerValue, forceUpdate] = useReducer(x => x + 1, 0);


  const [display, setDisplay] = useState(false);

  const history = useHistory();


  const follow = () => {


    var data = JSON.stringify({
      "id": id
    });

    var config = {
      method: 'post',
      url: `${server}follow/followUser`,
      headers: {
        'Authorization': token,
        'Content-Type': 'application/json'
      },
      data: data
    };

    axios(config)
      .then(function (result) {
        forceUpdate();
      });

  }


  const unFollow = () => {

    var data = JSON.stringify({
      "id": id
    });

    var config = {
      method: 'post',
      url: `${server}follow/unfollowUser`,
      headers: {
        'Authorization': token,
        'Content-Type': 'application/json'
      },
      data: data
    };

    axios(config)
      .then(function (result) {
        forceUpdate();
      });

  }

  useEffect(() => {


    if (token !== null) {
      setShow(true);

    } else {
      setShow(false);
    }


  }, [])





  useEffect(() => {

    const fetchData = async () => {


      setDisplay(false);
      var data = JSON.stringify({
        "id": id
      });

      var config = {
        method: 'post',
        url: `${server}follow/followcheck`,
        headers: {
          'Authorization': token,
          'Content-Type': 'application/json'
        },
        data: data
      };

      axios(config)
        .then(function (result) {
          if (result.data.status === true) {
            setFollowhide(result.data.following);
          } else {
            setFollowhide("none");
          }

        })





      var data1 = JSON.stringify({
        id: id
      });
      var config1 = {
        method: 'post',
        url: `${server}user/profileDetails`,
        headers: {
          'Content-Type': 'application/json',
        },
        data: data1
      };

      axios(config1)
        .then(function (response1) {


          if (response1.data.status === true) {
            setDisplay(true);
            setProfileData(response1.data.user_data);
            setFollowings(response1.data.following);
            setFollowers(response1.data.followers);
            setQuestionsData(response1.data.questions);

          }

        })
    }
    fetchData()
  }, [reducerValue, server, id])
  return (
    <>
      {colorMode === "light" ?
        <>
          <Image src={back} bgSize="cover" alt='Dan Abramov' width='100%' position="fixed" top="0" zIndex="-1" />

        </>
        : <Image src={back1} alt='Dan Abramov' width='100%' position="fixed" top="0" zIndex="-1" />
      }

      <Flex gap='2' direction='row' pt={{ base: "120px", md: "75px" }} h='100%' className='profile1'>


        {display === true ?
          <>
            <Card maxW='sm'  >

              <Button style={{ width: '25%' }} onClick={() => { history.push("/#/auth/home"), sessionStorage.removeItem("userId"); }} >
                <Icon as={FaArrowCircleLeft} color='white.400' fontWeight="700" fontSize='md' me='6px'></Icon>Back
              </Button>

              <Flex direction="column" alignItems='center' >

                <Avatar
                  size="2xl"

                  cursor="pointer"

                  src={'http://ec2-34-201-174-68.compute-1.amazonaws.com:3000/' + profileData.image}
                >
                </Avatar>
                <Flex direction="column" p="2"  >

                  <Text as='b' fontFamily={theme.fonts.userName} >  {profileData.firstName} {profileData.lastName}    </Text>

                </Flex>

                {show === true ? <>

                  {Followhide === true ?
                    <Button fontFamily={theme.fonts.button} fontSize='xs' color="white" borderRadius="30px" backgroundColor="gray.600" mx='2px' px="6" fontWeight='bold' _hover={{ color: "gray.600", bg: "gray.200" }} onClick={unFollow}>Unfollow </Button>
                    : Followhide === false ? <Button fontFamily={theme.fonts.button} fontSize='xs' color="white" borderRadius="30px" backgroundColor="gray.600" mx='2px' px="6" fontWeight='bold' _hover={{ color: "gray.600", bg: "gray.200" }} onClick={follow}>Follow</Button>
                      : ""}
                </> : ""}

              </Flex>

              <Flex gap='4' alignSelf='center' justifyContent="center">
                <Grid  >
                  <Text textAlign='center' >    {Followers}    </Text>

                  <Text textAlign='center' fontSize='xl' color={textColor} fontWeight='bold'> <Icon color={textColor} as={FaUserPlus} me='6px' /> Followers  </Text>
                </Grid>
                <Grid  >
                  <Text textAlign='center' > {Followings} </Text>
                  <Text textAlign='center' fontSize='xl' color={textColor} fontWeight='bold'>   <Icon color={textColor} as={FaUserCheck} me='6px' /> Followings</Text>
                </Grid>
              </Flex>
            </Card>

            <Card maxW='6xl'   >
              <Tabs variant='enclosed'>
                <TabList style={{ width: '100%' }}>
                  <Tab fontFamily={theme.fonts.userAbout}>  Profile Information</Tab>

                  <Tab fontFamily={theme.fonts.userAbout}>  Knowledge Sharing </Tab>

                </TabList>

                <TabPanels>
                  <TabPanel>
                    <Card style={{ boxShadow: 'none' }}   >
                      <CardHeader p='12px 12px' mb='6px'>  <Text fontSize='xl' textAlign="center" borderRadius="10px" bg={"gray.600"} color={'orange.50'} fontFamily={theme.fonts.userAbout} fontWeight='bold'>   About Me  </Text>    </CardHeader>
                      <CardBody px='3px'>
                        <Flex direction='column'>
                          <Text fontSize='md' color={textColor} fontWeight='400' mb='30px'>
                            {profileData.aboutMe}
                          </Text>
                          <Flex align='center' mb='18px'>
                            <Text
                              fontSize='md'
                              color={textColor}
                              fontWeight='bold'
                              me='10px' fontFamily={theme.fonts.userAbout} w="150px">
                              Full Name : { }
                            </Text>
                            <Text fontSize='md' color={textColorAbout} fontWeight='400' fontFamily={theme.fonts.userName} >
                              {profileData.firstName} {profileData.lastName}
                            </Text>
                          </Flex>

                          <Flex align='center' mb='18px'>
                            <Text
                              fontSize='md'
                              color={textColor}
                              fontWeight='bold'
                              me='10px' fontFamily={theme.fonts.userAbout} w="150px">
                              Email :{" "}
                            </Text>
                            <Text fontSize='md' color={textColorAbout} fontWeight='400' fontFamily={theme.fonts.userName}>
                              {profileData.email}
                            </Text>
                          </Flex>
                          <Flex align='center' mb='18px'>
                            <Text
                              fontSize='md'
                              color={textColor}
                              fontWeight='bold'
                              me='10px' fontFamily={theme.fonts.userAbout} w="150px">
                              Company Name :{" "}
                            </Text>
                            <Text fontSize='md' color={textColorAbout} fontWeight='400' fontFamily={theme.fonts.userName}>
                              {profileData.companyName}
                            </Text>
                          </Flex>

                          <Flex align='center' mb='18px'>
                            <Text
                              fontSize='md'
                              color={textColor}
                              fontWeight='bold'
                              me='10px' fontFamily={theme.fonts.userAbout} w="150px">
                              Joined Date :{" "}
                            </Text>
                            <Text fontSize='md' color={textColorAbout} fontWeight='400' fontFamily={theme.fonts.userName}>

                              {moment(profileData.createdAt).format("DD-MM-YYYY hh:mm")}

                            </Text>
                          </Flex>

                        </Flex>


                      </CardBody>
                    </Card>
                  </TabPanel>

                  <TabPanel  >

                    <Card style={{ boxShadow: 'none' }}  >
                      <CardHeader >   </CardHeader>
                      <CardBody px='3px'>
                        <Flex direction='column'>

                          {questionsData.map((row, index, arr) => {
                            return (

                              <>
                                <Flex gap="5px">

                                  <Text fontSize='md' fontFamily={theme.fonts.userName} color='black.400' fontWeight='600' > <Link href={'#/auth/article'} color='red.400' onClick={(e) => { sessionStorage.setItem("articleId", row.id); }} > {index + 1}. {row.title}  </Link> </Text>   -  <Text fontSize='15px' fontWeight='200' bg="gray.500" borderRadius="20px" px="10px" py="4px" color="gray.50" _hover={{ bg: "gray.200", color: "gray.600" }} fontFamily={theme.fonts.category} >  {row.category.title}  </Text>

                                </Flex>

                              </>
                            )
                          })}
                        </Flex>

                      </CardBody>
                    </Card>

                  </TabPanel>

                </TabPanels>
              </Tabs>

            </Card>
          </>
          :

          <Card maxW='7xl' >
            <div className="loader"></div>
          </Card>
        }
      </Flex>




    </>
  );
}

export default Profile;
