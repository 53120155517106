import { mode } from "@chakra-ui/theme-tools";

export const globalStyles = {
  colors: {
    gray: {
      700: "#1f2733",
      600: "#071e22"
    },
    navy: {
      50: "#d0dcfb",
      100: "#aac0fe",
      200: "#a3b9f8",
      300: "#728fea",
      400: "#3652ba",
      500: "#1b3bbb",
      600: "#24388a",
      600: "#24388a",
      700: "#1b254b",
      800: "#111c44",
      900: "#0b1437",
    },
    blue: {
      50: "#EAF3FA",
      100: "#C5DCF1",
      200: "#A0C6E8",
      300: "#7BAFDF",
      400: "#5699D7",
      500: "#3182CE",
      600: "#2868A4",
      700: "#1E4E7B",
      800: "#143452",
      900: "#0A1A29"
    },
    orange: {
      50: "#FCF0E9",
      100: "#F6D6C1",
      200: "#F0BB99",
      300: "#EAA171",
      400: "#E48649",
      500: "#DE6C21",
      600: "#B2561A",
      700: "#854114",
      800: "#592B0D",
      900: "#2C1607"
    },
    red: {
      50: "#FCE8E8",
      100: "#F7BFBF",
      200: "#F29696",
      300: "#ED6D6D",
      400: "#E94444",
      500: "#E41B1B",
      600: "#B61616",
      700: "#891010",
      800: "#5B0B0B",
      900: "#2E0505"
    },
    cyan: {
      50: "#E6FBFF",
      100: "#B8F3FE",
      200: "#8BEBFE",
      300: "#5DE4FE",
      400: "#30DCFD",
      500: "#02D4FD",
      600: "#02AACA",
      700: "#017F98",
      800: "#015565",
      900: "#002A33"
    }
  },
  styles: {
    global: (props) => ({
      body: {
        overflowX: "hidden",
        bg: mode("gray.50", "#1B254B")(props),
        fontFamily: "fjalla-one, sans-serif",
      },
      html: {
        fontFamily: "fjalla-one, sans-serif",
      },
    }),
  },
};
