// Chakra imports
import React, { useEffect, useRef, useState, useReducer }  from 'react';
import {
  useDisclosure,
  Box,
  Button,
  Flex,
  Grid,
  Icon,
  Spacer,
  Text,
  useColorMode,
  useColorModeValue,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Input,
  Textarea,
  FormLabel,
  Image,
  chakra,
  Container,
  Link,
  Stack,
  VisuallyHidden, 
   GridItem,
  FormControl,
  Select,
  Checkbox, 
  CheckboxGroup,
  Avatar,
  Heading,
  IconButton,
  Divider,
  Center
} from "@chakra-ui/react";
 
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import CardFooter from "components/Card/CardFooter";

import { BsThreeDotsVertical } from "react-icons/bs"

import { AiFillLike } from "react-icons/ai"
import PropTypes from 'prop-types'; 

 import axios from 'axios';
import { notification } from 'antd';
import moment from "moment";
import { FaUserCircle, FaEdit } from "react-icons/fa"; 
import { BiLike, BiChat, BiShare  } from "react-icons/bi"; 
import { Menu,
  MenuButton,
  MenuItem,
  MenuList,} from '@chakra-ui/react'
import jwt_decode from "jwt-decode";
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { useHistory } from 'react-router-dom';
 
 import { Paginate } from "react-paginate-chakra-ui";
 import back from "./1.png"
import back1 from "./2.png"

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`ori-tabpanel-${index}`}
      aria-labelledby={`vertical -tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
}


TabPanel.propTypes = {
children: PropTypes.node,
index: PropTypes.number.isRequired,
value: PropTypes.number.isRequired,
};

function a11yProps(index) {
return {
  id: `vertical -tab-${index}`,
  'aria-controls': `vertical -tabpanel-${index}`,
};
}


 function Home1( ) {



  // Chakra color mode
  const iconBlue = useColorModeValue("blue.500", "blue.500");
  const textColor = useColorModeValue("gray.700", "white");
  const borderColor = useColorModeValue("#dee2e6", "transparent");
  const { colorMode } = useColorMode();
 

  const Edit = useDisclosure() 
  
  const { isOpen, onOpen, onClose } = useDisclosure();


  const [tableData,setTableData] = useState([]);
  const [tableCount,setTableCount] = useState(1);
  const [likeData,setLikeData] = useState([]);
   const [ Followhide, setFollowhide ] = useState([]);
  const [categoryData, setCategoryData] = useState([]);

  const [ userId,setUserId] = useState(""); 
  const [show, setShow] = useState(false);

  const history = useHistory();

 
  const [reducerValue, forceUpdate] = useReducer(x => x + 1, 0);

  const server= "https://23.20.212.243:8080/api/";
  // const server= "http://ec2-34-201-174-68.compute-1.amazonaws.com:3000/api/";
  
  const token = sessionStorage.getItem("token");


  const [edit,setEdit] = useState([]);
  const EditForm = useRef(null)

 
 
  const [page, setPage] = useState(0);
  const [popular, setPopular] = useState([]);
  const [ foundSearch , setFoundSearch ] = useState(tableData);

   const [followLoad, setFollowLoad] = useState(false);

  const [value, setValue] = React.useState(0);
  const [display, setDisplay] = useState(false);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

/** questionEdit */

    const questionEdit = () => {
      const form = EditForm.current
              
          var data = JSON.stringify({
          "id":`${form['id'].value}`,
          "title":`${form['title'].value}`,
          "description":`${form['description'].value}`,
          "categoryId":`${form['category'].value}`
          }); 
    
          var config = {
          method: 'post',
          url: `${server}question/editQuestions`,
          headers: { 
          'Authorization': token, 
          'Content-Type': 'application/json'
          },
          data : data
          };
    
          axios(config)
          .then(function (result) {
    
       
          if(result.data.status === true){
            Edit.onClose();
            forceUpdate();
            notification.success({ 
              top: '10%',
              placement: 'topRight',
              message: result.data.message, 
              onClose: () => {  
                 
              },
          }); 
          } else {
            notification.error({ 
              top: '10%',
              placement: 'topRight',
              message: result.data.message, 
          }); 
          } 
    })
    .catch(function (error) {
      if(error.code === "ERR_NETWORK"){
        notification.error({ 
          top: '10%',
          placement: 'topRight',
          message: result.data.message,
      });
      } else {
        const msg = JSON.parse(error.request.response);  
        notification.error({ 
          top: '10%',
          placement: 'topRight',
          message: msg.message,
      });
      }
    });
    
    }


    /** add Like */

 function addLike(id)  { 
  var data = JSON.stringify({
    id: id
  }); 
  var config = {
  method: 'post',
  url: `${server}question/likeQuestion`,
  headers: { 
   'Authorization': token, 
  'Content-Type': 'application/json'
  },
  data : data
 
  };
 
  axios(config)
  .then(function (response) {   
   
    forceUpdate();
  
  })
 
  .catch(function (error) {
  console.log(error);
  }); 
   
}

   /** remove Like */
  function removeLike(id)  {
  var data = JSON.stringify({
    id: id
  }); 
  var config = {
  method: 'post',
  url: `${server}question/unlikeQuestion`,
  headers: { 
   'Authorization': token, 
  'Content-Type': 'application/json'
  },
  data : data
 
  };
 
  axios(config)
  .then(function (response) {   

    forceUpdate();
  
  })
 
  .catch(function (error) {
  console.log(error);
  }); 

}

   /** follow */

 
   function follow(id)  {
    
    setFollowLoad(true);
  var data = JSON.stringify({
      "id":id
      }); 

      var config = {
      method: 'post',
      url: `${server}follow/followUser`,
      headers: { 
      'Authorization': token, 
      'Content-Type': 'application/json'
      },
      data : data
      };

      axios(config)
      .then(function (result) {

     
        forceUpdate(); 
        if(result.data.status === true) {
        setFollowLoad(false);
        }
});

}
    /** unFollow */

  function unFollow(id)  {
    setFollowLoad(true);
    
var data = JSON.stringify({
  "id":id
  }); 

  var config = {
  method: 'post',
  url: `${server}follow/unfollowUser`,
  headers: { 
  'Authorization': token, 
  'Content-Type': 'application/json'
  },
  data : data
  };

  axios(config)
  .then(function (result) {
 
    forceUpdate(); 

    if(result.data.status === true) {
      setFollowLoad(false);

    }

});

} 

useEffect(() => { 

  var data = JSON.stringify({}); 
  var config = {
    method: 'post',
    url: `${server}auth/viewAllCategory`,
    headers: { 
      'Authorization': token, 
      'Content-Type': 'application/json'
    },
    data : data
  };
  axios(config)
  .then(function (response) { 
  
    if(response.data.status === true){
      setCategoryData(response.data.data);
  
    }
  })
  .catch(function (error) {
    console.log(error);
  }); 
}, [server])

useEffect(() => { 
 
  const fetchData = async () =>{ 
 var data = JSON.stringify({   }); 
 var config = {
 method: 'post',
 url: `${server}question/questionLike`,
 headers: { 
  'Authorization': token, 
 'Content-Type': 'application/json'
 },
 data : data
 };
 
 axios(config)
 .then(function (response) {   
 
  
  if(response.data.status === true){
 
    setLikeData(response.data.data); 
       
   
}
 })
 .catch(function (error) {
 console.log(error);
 }); 


 
 var data1 = JSON.stringify({
  id: userId
}); 
var config1 = {
method: 'post',
url: `${server}user/isFollow`,
headers: { 
'Authorization': token, 
'Content-Type': 'application/json'
},
data : data1

};

axios(config1)
.then(function (response1) {  
 
if(response1.data.status===true){
  setFollowhide(response1.data.data);
} else {
setFollowhide("none"); 
}

})

.catch(function (error) {
console.log(error);
}); 


 
  }
   
 fetchData()

}, [reducerValue, server])


 

useEffect(() => { 
 
  window.addEventListener('storage', handleStorage);


  if((token === null || token === undefined)) {     
    setShow(false);
 
} else{
  setShow(true);
  var decoded = jwt_decode(token);

 setUserId(decoded.user_id);

}
 
 }, [token])


  const handlePageClick = (p) => { 


    setPage(p)

    if(tableCount >= 10) {

      setDisplay(false);
      const search = window.sessionStorage.getItem("search");

      if(search !== null || search !== undefined) { 

      var data = JSON.stringify({ type: "search", "search": search, page: p+1 }); 
      }  
  
 

  if((token === null || token === undefined)) {     
    var url = `${server}auth/getQuestions`;
    } else{ 
    var url = `${server}auth/allQuestions`;
    }

   
    var config = {
      method: 'post',
      url: url,
      headers: {  
        'Authorization': token, 
      'Content-Type': 'application/json'
      },
      data : data
      };
      
      axios(config)
      .then(function (response) {   
     
      
       if(response.data.status === true){
         
       
        setDisplay(true);
           setTableData(response.data.data); 
         
         setTableCount(response.data.count); 
     }
      })
      .catch(function (error) {
      console.log(error);
      }); 
  
  
 
} 

  }
 

const handleStorage = () => {
 
   const search =window.sessionStorage.getItem("search");

    if (search !== '') {
    
      setDisplay(false);

      if((token === null || token === undefined)) {     
        var url = `${server}auth/getQuestions`;
        } else{ 
        var url = `${server}auth/allQuestions`;
        }

      var data =  JSON.stringify({ type: "search" ,search: search, page: 1 }); 


        var config = {
        method: 'post',
        url: url,
        headers: { 
        'Authorization': token, 
        'Content-Type': 'application/json'
        },
        data : data
        };
        axios(config)
        .then(function (result) { 
         

     if(result.data.status === true){
    setDisplay(true); 
  
  setPage(0);
      setTableData(result.data.data); 
      setFoundSearch(result.data.data); 
      setTableCount(result.data.count);
    }  
  })
  .catch(function (error) {
    console.log(error);
  });

    } else {
      setFoundSearch(tableData);
     
    }
 
 
}
  

 useEffect(() => { 
   const fetchData = async () =>{  
 
    const search =window.sessionStorage.getItem("search");
    var data =  JSON.stringify({ type: "search" ,"search": search, page: 1 }); 

if((token === null || token === undefined)) {     
var url = `${server}auth/getQuestions`;
} else{ 
var url = `${server}auth/allQuestions`;
}

 var config = {
 method: 'post',
 url: url,
 headers: { 
  'Authorization': token, 
 'Content-Type': 'application/json'
 },
 data : data
 };
 
 axios(config)
 .then(function (response) {   
 
 
 if(response.data.status === true){
   setPage(0);
  setDisplay(true);
   setTableData(response.data.data); 
  
    setTableCount(response.data.count); 
}
 })
 .catch(function (error) {
 console.log(error);
 }); 

} 

fetchData()

}, [reducerValue, server])


 
useEffect(() => { 
 
  const fetchData = async () =>{ 
 var data = JSON.stringify({   }); 
 var config = {
 method: 'post',
 url: `${server}question/popularQuestion`,
 headers: { 
  'Authorization': token, 
 'Content-Type': 'application/json'
 },
 data : data
 };
 
 axios(config)
 .then(function (response) {   
  
  
 if(response.data.status === true){
 
   setPopular(response.data.data); 
 
}
 })
 .catch(function (error) {
 console.log(error);
 }); 
 
  }
   
 fetchData()

}, [])

  return (
    <>


{colorMode === "light" ? 
<>
<Image src={back} alt='Dan Abramov'  width='100%' position="fixed" top="0" zIndex="-1"/>

</>
:  <Image src={back1} alt='Dan Abramov'  width='100%' position="fixed" top="0" zIndex="-1"/>
}  

    <Flex direction='column' pt={{ base: "120px", md: "75px" }}    h='100%' className="search">
   

      <Card  my={{ lg: "24px" }} me={{ lg: "24px" }}  >
   

      
      <Card   maxW='7xl' >
      <Grid templateColumns='repeat(4, 1fr)' >
      <GridItem  mb='20px'>
         <Flex alignItems='center'>
         
         <Tabs
                orientation='horizontal '
        value={value}
        onChange={handleChange}
        aria-label="horizontal  tabs example"
        variant='soft-rounded' colorScheme='green'
      >
            <Tab label="Search Results"  {...a11yProps(0)}></Tab>

           
      </Tabs>
         </Flex>
        </GridItem>
        
    </Grid>
    <Divider />
    
    <Grid templateColumns='repeat(5, 1fr)' mt='20px'>
  
   
      <GridItem pr='10px' colSpan={3} >

      { display===true? 
   <>

      <Heading as='h4' size='sm' mb='20px'>   Topics </Heading>
      <Divider />
      <TabPanel value={value} index={0}  className='home_card'>
   
      { tableData.length>0 ?  
tableData.map((row) => {
 
  return (
    <> 
  <Card  boxShadow='none' >
  <CardHeader  pt='2'>
    <Flex spacing='4'>
      <Flex flex='1' alignItems='center' flexWrap='wrap'>
     
        <Icon  as={FaUserCircle}  color='white.400'    fontSize='30px'  me='6px' ></Icon> 
        <Box>

          <Flex gap="2"> 
          <Heading size='sm'><Link color="blue.400" href={'#/auth/profile/'} onClick={(e)=>{  sessionStorage.setItem("userId",row.userId); }}>{ row.user.firstName }</Link></Heading>

          {show === true?  

row.userId === userId ? "" :

Followhide.includes(row.userId)===true ?   
  followLoad === false?  
  <Button   h='1.75rem' size='sm'  fontWeight='bold' onClick={()=> { unFollow(row.userId) }}>Unfollow </Button>
 :
  <Button isLoading h='1.75rem' size='sm'  colorScheme='teal'    spinnerPlacement='start'  >  Submit </Button>
 
  : 
  
  followLoad === false?  
  <Button   h='1.75rem' size='sm' fontWeight='bold' onClick={()=>{ follow(row.userId) }}>Follow</Button> 
  :
  <Button isLoading h='1.75rem' size='sm'  colorScheme='teal'    spinnerPlacement='start'>  Submit </Button>

  :"" } </Flex>
          <Text>{ row.category.title }, {moment(row.createdAt).format("DD-MM-YYYY hh:mm")}</Text>
        </Box>
      </Flex>
      
      { row.userId === userId?  
             <>

      <Menu>
                <MenuButton
                  as={IconButton}
                  aria-label='Options' 
                  icon={<BsThreeDotsVertical />}
                  variant='outline'
                  
                />

 
                <MenuList>
               
              
             
                  <MenuItem >

                  <Flex   onClick={() => {Edit.onOpen(); setEdit(
                   {
                       "id": row.id,
                       "title": row.title,
                       "description": row.description,
                       "category":row.id,
                   }
               );}}  >  <Icon  as={FaEdit}  color='white.400'    fontSize='15px'  me='6px' ></Icon>  Edit </Flex>
              
            

                  </MenuItem>
               

                </MenuList>
              </Menu>
              </>  :  ""   }  
    </Flex>
  </CardHeader>

  <CardBody pt='2'>

   <Heading  className='question' size='sm'><Link color="black.400" href={'#/auth/article'} onClick={(e)=>{  sessionStorage.setItem("articleId",row.id); }} > { row.title.charAt(0).toUpperCase() + row.title.slice(1) }</Link></Heading>

<Flex  >
    <Text  pt='2'>

{ row.description.length > 400 ? <>
      {  row.description.substring(0,500)+"..." } 
      <Link color="blue.400" href={'#/auth/article'} onClick={(e)=>{  sessionStorage.setItem("articleId",row.id); }} >Read More</Link>
      </>
:  row.description  }
    </Text>
    </Flex>
  </CardBody> 

  <CardFooter  pt='2'
    justify='left'
    gap='2'
    flexWrap='wrap'
    sx={{
      '& > button': {
        minW: '136px',
      },
    }}
  >
 
         
        


 {show === true? <> 
        
        <Flex alignItems='center' gap="2" >


        <Flex alignItems='center' onClick={() => {  likeData.includes(row.id)===true ? removeLike(row.id) : addLike(row.id)  } } gap='2' > 
         <Icon  as={  likeData.includes(row.id)===true?  AiFillLike: BiLike  }  color='black.400'    fontSize='15px'   style={{cursor:'pointer'}}  >  </Icon>   {row.likes_count}  </Flex>  

         <Flex alignItems='center' onClick={() => { history.push('article')  } }   gap='2' style={{cursor:'pointer'}}  > 
          <Icon  as={BiChat}  color='white.400'    fontSize='15px'  >  </Icon>    <Text fontSize='18px'>{row.comments.length}</Text>  </Flex> 
</Flex>  
</> : 
<> 
 <Flex gap="2">
<Flex  alignItems='center'  onClick={() => { onOpen() } } gap='2'    style={{cursor:'pointer'}}  >  <Icon  as={BiLike}  color='black.400'    fontSize='15px' >  </Icon> {row.likes_count}  </Flex> 

<Flex  alignItems='center'  onClick={() => {  onOpen()  } }  gap='2'  style={{cursor:'pointer'}}>  <Icon  as={BiChat}  color='white.400'    fontSize='15px'  >  </Icon>    {row.comments.length} </Flex>  
</Flex>  
</>
}  



 
   

     

      
 
    
    
  </CardFooter>
</Card>
 
<Divider orientation='horizontal' border='0' borderColor='rgb(192 188 188)' borderStyle='solid' borderBottomWidth='1px' width='100%' marginTop='20px'/>
</>
  );
})

:  <Card  boxShadow='none' >No Results Found </Card>
}

</TabPanel>

<TabPanel value={value} index={1}  className='home_card'>


{tableData.map((row) => {

 

return (
  <> 
<Card  boxShadow='none' >
<CardHeader  pt='2'>
  <Flex spacing='4'>
    <Flex flex='1' alignItems='center' flexWrap='wrap'>
   
      <Icon  as={FaUserCircle}  color='white.400'    fontSize='30px'  me='6px' ></Icon> 
      <Box>

        <Flex gap="2"> 
        <Heading size='sm'><Link color="blue.400" href={'#/auth/profile/'} onClick={(e)=>{  sessionStorage.setItem("userId",row.userId); }}>{ row.user.firstName }</Link></Heading>

        {show === true?  

row.userId === userId ? "" :

Followhide.includes(row.userId)===true ?   
  followLoad === false?  
  <Button   h='1.75rem' size='sm'  fontWeight='bold' onClick={()=> { unFollow(row.userId) }}>Unfollow </Button>
 :
  <Button isLoading h='1.75rem' size='sm'  colorScheme='teal'    spinnerPlacement='start'  >  Submit </Button>
 
  : 
  
  followLoad === false?  
  <Button   h='1.75rem' size='sm' fontWeight='bold' onClick={()=>{ follow(row.userId) }}>Follow</Button> 
  :
  <Button isLoading h='1.75rem' size='sm'  colorScheme='teal'    spinnerPlacement='start'>  Submit </Button>

  :"" }</Flex>
        <Text>{ row.category.title }, {moment(row.createdAt).format("DD-MM-YYYY hh:mm")}</Text>
      </Box>
    </Flex>
    
    { row.userId === userId?  
           <>

    <Menu>
              <MenuButton
                as={IconButton}
                aria-label='Options' 
                icon={<BsThreeDotsVertical />}
                variant='outline'
                
              />


              <MenuList>
             
            
           
                <MenuItem >

                <Flex   onClick={() => {Edit.onOpen(); setEdit(
                 {
                     "id": row.id,
                     "title": row.title,
                     "description": row.description,
                     "category":row.id,
                 }
             );}}  >  <Icon  as={FaEdit}  color='white.400'    fontSize='15px'  me='6px' ></Icon>  Edit </Flex>
            
          

                </MenuItem>
             

              </MenuList>
            </Menu>
            </>  :  ""   }  
  </Flex>
</CardHeader>

<CardBody pt='2'>

 <Heading  className='question' size='sm'><Link color="black.400" href={'#/auth/article'} onClick={(e)=>{  sessionStorage.setItem("articleId",row.id); }}  > { row.title.charAt(0).toUpperCase() + row.title.slice(1) }</Link></Heading>

<Flex  >
  <Text  pt='2'>

{ row.description.length > 400 ? <>
    {  row.description.substring(0,500)+"..." } 
    <Link color="blue.400" href={'#/auth/article'} onClick={(e)=>{  sessionStorage.setItem("articleId",row.id); }} >Read More</Link>
    </>
:  row.description  }
  </Text>
  </Flex>
</CardBody> 

<CardFooter  pt='2'
  justify='left'
  gap='2'
  flexWrap='wrap'
  sx={{
    '& > button': {
      minW: '136px',
    },
  }}
>

       
      


{show === true? <> 
        
        <Flex alignItems='center' gap="2" >


        <Flex alignItems='center' onClick={() => {  likeData.includes(row.id) ? removeLike(row.id) : addLike(row.id)  } } gap='2' > 
         <Icon  as={  likeData.includes(row.id)===true?  AiFillLike: BiLike  }  color='black.400'    fontSize='15px'   style={{cursor:'pointer'}}  >  </Icon>   {row.likes_count}  </Flex>  

         <Flex alignItems='center' onClick={() => { history.push('article')  } }   gap='2' style={{cursor:'pointer'}}  > 
          <Icon  as={BiChat}  color='white.400'    fontSize='15px'  >  </Icon>    <Text fontSize='18px'>{row.comments.length}</Text>  </Flex> 
</Flex>  
</> : 
<> 
 <Flex gap="2">
<Flex  alignItems='center'  onClick={() => { onOpen() } } gap='2'    style={{cursor:'pointer'}}  >  <Icon  as={BiLike}  color='black.400'    fontSize='15px' >  </Icon> {row.likes_count}  </Flex> 

<Flex  alignItems='center'  onClick={() => {  onOpen()  } }  gap='2'  style={{cursor:'pointer'}}>  <Icon  as={BiChat}  color='white.400'    fontSize='15px'  >  </Icon>    {row.comments.length} </Flex>  
</Flex>  
</>
}  




 

   

    

  
  
</CardFooter>
</Card>

<Divider orientation='horizontal' border='0' borderColor='rgb(192 188 188)' borderStyle='solid' borderBottomWidth='1px' width='100%' marginTop='20px'/>

 
</>
);
})}
</TabPanel>

<Flex   direction='column' w='100%' align="center">

<Paginate
page={page}
margin={1}
shadow="sm"
fontWeight="bold"
variant="outline"
selectedVariant="solid"
count={tableCount}
pageSize={10}
onPageChange={handlePageClick}
/>
</Flex>


</>
:
<div className="loader"></div>
}
</GridItem>



<GridItem pl='10px' colSpan={2} className='home_card'>
<Heading as='h4' size='sm' mb='20px'>   Most Popular questions </Heading>
      <Divider />
        <Card boxShadow='none'>
           
        <CardHeader>
          </CardHeader>


       
          <CardBody  >
         
          {popular.map((row, index, arr)=> {  
            return(
              <>
                <Card  boxShadow='none'>
                   <Text>
                      <Link href={'/#/auth/article'} color="blue.400"  onClick={(e)=>{  sessionStorage.setItem("articleId",row.id); }} >  {row.title}</Link>
                      
                       - {row.category.title}     </Text>
                      
                    <Flex spacing='4' >
                      <Text>Description:     { row.description.length > 150 ? <>
    {  row.description.substring(0,150)+"..." } 
    <Link color="blue.400" href={'#/auth/article'} onClick={(e)=>{  sessionStorage.setItem("articleId",row.id); }} >Read More</Link>
    </>
:  row.description  } </Text>
                      
                    </Flex>
                    <Divider />
                </Card>
           
              </>
            );
          })}
         
          </CardBody>
         
        </Card>
        </GridItem>

</Grid>      


     
  
   </Card >

 
  
  


        </Card>
     
  

      <Modal
       
       
        isOpen={Edit.isOpen}
        onClose={Edit.onClose}
        size="4xl" 
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Edit</ModalHeader>
          <ModalCloseButton />
          <form ref={EditForm}>
            <ModalBody pb={6}>
        
            <FormControl>
              <Input variant='auth' fontSize='sm' ms='4px' type='hidden' mb='24px' size='lg' defaultValue={edit.id}
             name='id'
            /> 
              <Input variant='auth' fontSize='sm'  ms='4px' type='text' mb='24px' size='lg' defaultValue={edit.title}
              name='title'
            />
 
             <Textarea name='description' defaultValue={edit.description}  ></Textarea>


               <Select placeholder='Select category' name='category' mt={4} defaultValue={edit.category}>
            {categoryData.map((row, index, arr)=> {  
            return (
            <option value={row.id}>{row.title}</option>
            );
            })}
            </Select>
            </FormControl>
          </ModalBody>

          <ModalFooter>
            <Button colorScheme='blue' mr={3} onClick={questionEdit}>   Save  </Button>
          </ModalFooter>
   
          </form>
        </ModalContent>
      </Modal>




 
      <Modal onClose={onClose} size="md" isOpen={isOpen}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader> Login Required</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
             <Text> You have not Logged in, Please Login </Text>
          </ModalBody>
          <ModalFooter>
            <Flex gap="2"> 
            <Button colorScheme='blue' onClick={ (e)=> { history.push('auth/sigin'); }}> Signin</Button>
            <Button onClick={onClose}>Close</Button>
            </Flex>
          </ModalFooter>
        </ModalContent>
      </Modal>

      
    </Flex>
    
    </>
  );
}

export default Home1;
