/*eslint-disable*/
import { Flex, Link, Box, useColorModeValue, Container, Stack, Text } from "@chakra-ui/react";
import React from "react";

import {FaInstagram ,FaTwitter,FaYoutube  } from "react-icons/fa";

 
export default function Footer(props) {
  return (
<>
<Box
  >
     <Container
       as={Stack}
       maxW={'6xl'}
       py={4}
       spacing={4}
       justify={'center'}
       align={'center'}>
       <Text  >Recruiter Community</Text>
       <Stack direction={'row'} spacing={6}>
         <Link href={'#/auth/home'}>Home</Link>
         <Link href={'#/auth/signin'}>Signin</Link>
         <Link href={'#/auth/signup'}>Signup</Link>
         <Link href={'#/auth/home'}>Contact</Link>
       </Stack>
     </Container>

     <Box
       borderTopWidth={1}
       borderStyle={'solid'}
       borderColor={useColorModeValue('gray.200', 'gray.700')}>
       <Container
         as={Stack}
         maxW={'6xl'}
         py={4}
         direction={{ base: 'column', md: 'row' }}
         spacing={4}
         justify={{ base: 'center', md: 'space-between' }}
         align={{ base: 'center', md: 'center' }}>
         <Text>Made with ❤️ by Infoapto</Text>
         <Stack direction={'row'} spacing={6} className="social_foot">
          <a href=""><FaInstagram /></a>
            <a href=""><FaTwitter /></a>
            <a href=''><FaYoutube /></a>
         </Stack>
       </Container>
     </Box>
   </Box>
    </>
  );
}
