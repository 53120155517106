// Chakra imports
import React, { useEffect, useRef, useState, useReducer } from 'react';
import { BiLike, BiChat, BiShare } from "react-icons/bi";
import { AiOutlineLike, AiFillLike } from "react-icons/ai";

import {
  Icon,
  Box,
  Button,
  Flex,
  Grid,
  GridItem,
  Link,
  Text,
  useColorMode,
  useColorModeValue,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Input,
  Textarea,
  FormLabel,
  Container,
  Stack,
  FormControl,
  HStack, VStack,
  Select, Avatar,
  Image,
  extendTheme,
} from "@chakra-ui/react";
// Assets


// Custom components
import Card from "components/Card/Card";
import {
  FaCube,
  FaPenFancy,
  FaPlus,
  FaArrowCircleLeft,
  FaUserCircle,
  FaInstagram,
  FaTwitter,
  FaYoutube,
  FaTelegramPlane,
  FaCommentDots,
  FaRegHeart,
  FaHeart,
  FaEdit,
  FaTrashAlt,
  FaCircle
} from "react-icons/fa";
import { Tabs, TabList, TabPanels, Tab, TabPanel } from '@chakra-ui/react'
import axios from 'axios';
import { notification } from 'antd';
import { RocketIcon } from 'components/Icons/Icons';
import moment from "moment";
import jwt_decode from "jwt-decode";
import { useHistory } from 'react-router-dom';
import back from "../../assets/img/back1.jpg"
import back1 from "./2.png"
import theme from 'theme/theme';


function Article() {

  // Chakra color mode
  const textColor = useColorModeValue("gray.700", "white");
  const iconColor = useColorModeValue("blue.500", "white");
  const bgProfile = useColorModeValue("hsla(0,0%,100%,.8)", "navy.800");
  const borderProfileColor = useColorModeValue("white", "transparent");
  const emailColor = useColorModeValue("gray.400", "gray.300");
  const { colorMode } = useColorMode();



  const [show, setShow] = useState(false);
  const [QuestionData, setQuestionData] = useState([]);

  const [commentData, setCommentData] = useState([]);

  const server= "https://23.20.212.243:8080/api/";
  console.log(server, "article");
  // const server= "http://ec2-34-201-174-68.compute-1.amazonaws.com:3000/api/";

  const token = sessionStorage.getItem("token");

  const QuestionsForm = useRef(null)
  const AddComment = useRef(null)
  const AskQuestionsForm = useRef(null)

  const EditCommentRef = useRef(null)
  const DeleteCommentRef = useRef(null)

  const EditComment = useDisclosure()
  const DeleteComment = useDisclosure()

  const [edit, setEdit] = useState([]);
  const EditForm = useRef(null)

  const Edit = useDisclosure()

  const { isOpen, onOpen, onClose } = useDisclosure();

  const [askdata, setAskData] = useState([]);

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const [Likehide, setLikehide] = useState(false);
  const [categoryData, setCategoryData] = useState([]);

  const [comment, setComment] = useState([]);

  const [auth, setAuth] = useState();

  const id = sessionStorage.getItem("articleId");

  const [reducerValue, forceUpdate] = useReducer(x => x + 1, 0);

  const history = useHistory();

  const [display, setDisplay] = useState(true);

  const addLike = () => {


    var data = JSON.stringify({
      id: id
    });
    var config = {
      method: 'post',
      url: `${server}question/likeQuestion`,
      headers: {
        'Authorization': token,
        'Content-Type': 'application/json'
      },
      data: data

    };

    axios(config)
      .then(function (response) {

        if (response.data.status === true) {
          forceUpdate();
          setLikehide(true);
        }

      })

      .catch(function (error) {
        console.log(error);
      });

  }

  const removeLike = () => {


    var data = JSON.stringify({
      id: id
    });
    var config = {
      method: 'post',
      url: `${server}question/unlikeQuestion`,
      headers: {
        'Authorization': token,
        'Content-Type': 'application/json'
      },
      data: data

    };

    axios(config)
      .then(function (response) {
        if (response.data.status === true) {
          forceUpdate();
          setLikehide(false);
        }

      })

      .catch(function (error) {
        console.log(error);
      });

  }


  const commentAdd = () => {
    const form = AddComment.current
    if (`${form['comment'].value}` !== "") {
      var data = JSON.stringify({
        "id": id,
        "comment": `${form['comment'].value}`,
      });

      var config = {
        method: 'post',
        url: `${server}comment/addComment`,
        headers: {
          'Authorization': token,
          'Content-Type': 'application/json'
        },
        data: data
      };

      axios(config)
        .then(function (result) {

          if (result.data.status === true) {

            forceUpdate();
            onClose();

            form.reset();

            notification.success({
              top: '10%',
              placement: 'topRight',
              message: result.data.message,
              onClose: () => {

              },
            });
          } else {
            notification.error({
              top: '10%',
              placement: 'topRight',
              message: result.data.message,
            });
          }
        })
        .catch(function (error) {
          if (error.code === "ERR_NETWORK") {
            notification.error({
              top: '10%',
              placement: 'topRight',
              message: result.data.message,
            });
          } else {
            const msg = JSON.parse(error.request.response);
            notification.error({
              top: '10%',
              placement: 'topRight',
              message: msg.message,
            });
          }
        });

    } else {
      notification.error({
        top: '10%',
        placement: 'topRight',
        message: "Comment Cann't Be Blank",
      });
    }
  }


  /** questionEdit */

  const questionEdit = () => {
    const form = EditForm.current

    var data = JSON.stringify({
      "id": `${form['id'].value}`,
      "title": `${form['title'].value}`,
      "description": `${form['description'].value}`,
      "categoryId": `${form['category'].value}`
    });

    var config = {
      method: 'post',
      url: `${server}question/editQuestions`,
      headers: {
        'Authorization': token,
        'Content-Type': 'application/json'
      },
      data: data
    };

    axios(config)
      .then(function (result) {


        if (result.data.status === true) {
          Edit.onClose();
          forceUpdate();
          notification.success({
            top: '10%',
            placement: 'topRight',
            message: result.data.message,
            onClose: () => {

            },
          });
        } else {
          notification.error({
            top: '10%',
            placement: 'topRight',
            message: result.data.message,
          });
        }
      })
      .catch(function (error) {
        if (error.code === "ERR_NETWORK") {
          notification.error({
            top: '10%',
            placement: 'topRight',
            message: result.data.message,
          });
        } else {
          const msg = JSON.parse(error.request.response);
          notification.error({
            top: '10%',
            placement: 'topRight',
            message: msg.message,
          });
        }
      });

  }



  useEffect(() => {

    var data = JSON.stringify({});
    var config = {
      method: 'post',
      url: `${server}auth/viewAllCategory`,
      headers: {
        'Authorization': token,
        'Content-Type': 'application/json'
      },
      data: data
    };
    axios(config)
      .then(function (response) {

        if (response.data.status === true) {
          setCategoryData(response.data.data);

        }
      })
      .catch(function (error) {
        console.log(error);
      });

  }, [server])


  const commentEdit = () => {
    const form = EditCommentRef.current



    if (`${form['comment'].value}` !== "") {
      var data = JSON.stringify({
        "id": `${form['id'].value}`,
        "comment": `${form['comment'].value}`,
      });

      var config = {
        method: 'post',
        url: `${server}comment/editComment`,
        headers: {
          'Authorization': token,
          'Content-Type': 'application/json'
        },
        data: data
      };

      axios(config)
        .then(function (result) {

          if (result.data.status === true) {

            forceUpdate();
            EditComment.onClose();
            form.reset();

            notification.success({
              top: '10%',
              placement: 'topRight',
              message: result.data.message,
              onClose: () => {

              },
            });
          } else {
            notification.error({
              top: '10%',
              placement: 'topRight',
              message: result.data.message,
            });
          }
        })
        .catch(function (error) {
          if (error.code === "ERR_NETWORK") {
            notification.error({
              top: '10%',
              placement: 'topRight',
              message: result.data.message,
            });
          } else {
            const msg = JSON.parse(error.request.response);
            notification.error({
              top: '10%',
              placement: 'topRight',
              message: msg.message,
            });
          }
        });

    } else {
      notification.error({
        top: '10%',
        placement: 'topRight',
        message: "Comment Cann't Be Blank",
      });
    }
  }


  function CommentDelete(id) {

    var data = JSON.stringify({
      id: id
    });
    var config = {
      method: 'post',
      url: `${server}comment/deleteComment`,
      headers: {
        'Authorization': token,
        'Content-Type': 'application/json'
      },
      data: data

    };

    axios(config)
      .then(function (response) {


        if (response.data.status === true) {
          forceUpdate();
          DeleteComment.onClose();
        }

      })

      .catch(function (error) {
        console.log(error);
      });


  }





  useEffect(() => {

    var token = sessionStorage.getItem("token");

    if (token !== null) {

      var decode = jwt_decode(token);

      setAuth(decode.user_id);

      setShow(true);

      var data = JSON.stringify({
        id: id
      });
      var config = {
        method: 'post',
        url: `${server}question/isLiked`,
        headers: {
          'Authorization': token,
          'Content-Type': 'application/json'
        },
        data: data

      };

      axios(config)
        .then(function (response) {
          if (response.data.status === true) {
            setLikehide(response.data.isLike);
          } else {
            setLikehide("none");
          }

        })

        .catch(function (error) {
          console.log(error);
        });


    } else {
      setShow(false);
      setAuth("");

    }

  }, [])


  useEffect(() => {


    const fetchData = async () => {

      var token = sessionStorage.getItem("token");

      if (token !== null) {

        var data = JSON.stringify({
          id: id
        });
        var config = {
          method: 'post',
          url: `${server}question/viewQuestion`,
          headers: {
            'Authorization': token,
            'Content-Type': 'application/json'
          },
          data: data

        };

        axios(config)
          .then(function (response) {
            if (response.data.status === true) {


              setQuestionData(response.data.data);
            }
          })

          .catch(function (error) {
            console.log(error);
          });

      } else {


        var data = JSON.stringify({
          id: id
        });
        var config = {
          method: 'post',
          url: `${server}question/questionView`,
          headers: {
            'Content-Type': 'application/json'
          },
          data: data

        };

        axios(config)
          .then(function (response) {
            if (response.data.status === true) {


              setQuestionData(response.data.data);
            }
          })

          .catch(function (error) {
            console.log(error);
          });

      }

    }


    fetchData()
  }, [reducerValue])


  useEffect(() => {


    const fetchData = async () => {
      var data = JSON.stringify({
        id: id
      });
      var config = {
        method: 'post',
        url: `${server}comment/getComments`,
        headers: {
          'Authorization': token,
          'Content-Type': 'application/json'
        },
        data: data

      };

      axios(config)
        .then(function (response) {
          if (response.data.status === true) {

            setCommentData(response.data.data.rows);
          }

        })

        .catch(function (error) {
          console.log(error);
        });

    }
    fetchData()
  }, [reducerValue])




  useEffect(() => {

    var data = JSON.stringify({});
    var config = {
      method: 'post',
      url: `${server}auth/viewAllCategory`,
      headers: {
        'Authorization': token,
        'Content-Type': 'application/json'
      },
      data: data

    };

    axios(config)
      .then(function (response) {

        if (response.data.status === true) {
          setAskData(response.data.data);
        }

      })

      .catch(function (error) {
        console.log(error);
      });


  }, [])



  return (
    <>

      {colorMode === "light" ?
        <>
          <Image src={back} alt='Dan Abramov' width='100%' position="fixed" top="0" zIndex="-1" />

        </>
        : <Image src={back1} alt='Dan Abramov' width='100%' position="fixed" top="0" zIndex="-1" />
      }
      <Flex gap='2' direction='row' pt={{ base: "120px", md: "75px" }} h='100%' className='article'>

        {display === true ?
          <>
            <Card maxW='100%' p="6" my={{ lg: "6px" }} ms={{ lg: "6px" }} borderRadius="35px 0px 35px 0px" gap="5px" >

              <Flex direction="row" gap="2">
                <Button style={{ width: '7%' }} onClick={() => { history.push("/#/auth/home") }} > <Icon as={FaArrowCircleLeft} color='white.400' fontWeight="700" fontSize='md' me='6px'></Icon>Back
                </Button>

                {QuestionData.userId === auth ? <>
                  <Button   >  <Flex direction="row" gap="2" alignItems="center" onClick={() => {
                    Edit.onOpen(); setEdit(
                      {
                        "id": QuestionData.id,
                        "title": QuestionData.title,
                        "description": QuestionData.description,
                        "category": QuestionData.categoryId,
                      }
                    );
                  }} style={{ cursor: 'pointer' }}   >   <Icon as={FaEdit} color='black.400' fontSize='15px' ></Icon> <Text> Edit</Text>
                  </Flex>  </Button> </> : ""}

              </Flex>

              <Flex direction="row" style={{ textTransform: "capitalize", justifyContent: 'center' }} gap="2" >
                <Text fontSize='xl' fontFamily={theme.fonts.title} fontWeight='600' color="red.400" style={{ textAlign: 'center' }}    >  {QuestionData.title}   </Text>

              </Flex>

              <Text color={textColor} fontFamily={theme.fonts.userAbout} fontSize='md' fontWeight='bold'  >  Author :<span style={{ fontWeight: '100' }}> <Link color="orange.400" ml={"30px"} fontFamily={theme.fonts.userName} href={'#/auth/profile'} onClick={(e) => { sessionStorage.setItem("userId", QuestionData.userId); }}>{QuestionData.user ? QuestionData.user.firstName : ""}</Link> </span>  </Text>


              <Text color={textColor} fontFamily={theme.fonts.userAbout} fontSize='md' fontWeight='bold' >  Category :<Text display="inline-block" ml="5px" fontSize="12px" fontFamily={theme.fonts.category} fontWeight='200' bg="gray.500" borderRadius="20px" px="10px" py='4px' color="gray.50" _hover={{ bg: "gray.200", color: "gray.600" }} style={{ fontWeight: '100' }}> {QuestionData.category ? QuestionData.category.title : ""} </Text>  </Text>

              <Text color={textColor} fontFamily={theme.fonts.userAbout} fontSize='md' fontWeight='bold' >  Description : </Text>  <Text fontSize='md' fontFamily={theme.fonts.message} fontWeight='500' color={textColor}  >   {QuestionData.description} </Text>


              <Flex direction={{ sm: "column", lg: "row" }} justify={{ sm: "center", lg: "space-between" }} align='left' w='100%' my={{ md: "12px" }}>

                <Text fontSize='md' color={textColor}>

                  {show === true ?

                    <>    <Flex gap='2' alignItems='center' flexWrap='wrap'>

                      {Likehide === true ? <Icon as={AiFillLike} color='gray.600' fontWeight='bold' fontSize='20px' style={{ cursor: 'pointer' }} onClick={removeLike} > </Icon>

                        : Likehide === false ? <Icon as={BiLike} color='gray.600' fontWeight='bold' fontSize='20px' style={{ cursor: 'pointer' }} onClick={addLike} > </Icon>

                          : ""}  {QuestionData.likes_count} </Flex>
                    </> :

                    <Flex gap='2' alignItems='center' flexWrap='wrap' onClick={() => { onOpen() }} style={{ cursor: 'pointer' }}  >   <Icon as={BiLike} color='black.400' fontSize='15px' fontWeight='bold'  >  </Icon>  {QuestionData.likes_count}   </Flex>
                  }

                </Text>

              </Flex>
              {show === true ? <>
                <Grid templateColumns={{ sm: "1fr" }}  >
                  <form ref={AddComment}>
                    <FormControl mt={4}>

                      <FormLabel fontFamily={theme.fonts.userAbout}>Comments</FormLabel>

                      <Textarea name='comment' placeholder='Add a comment...' border={{base:"none"}}></Textarea>

                      <Flex direction={{ sm: "column", lg: "row" }} justify={{ sm: "center", lg: "space-between" }} align={{ sm: "center" }} w='100%' my={{ md: "12px" }}>

                        <Flex align='left'>

                        </Flex>
                        <Flex align='left'>

                          <Button fontSize='xs' className='button-btn' fontFamily={theme.fonts.button} onClick={commentAdd}  >  <Icon as={FaTelegramPlane} fontSize='13px' me='6px' ></Icon>
                            comment</Button>

                        </Flex>
                      </Flex>

                    </FormControl>
                  </form>
                </Grid></>
                : ""}

              <Grid templateColumns={{ sm: "1fr" }}  >

                <Flex gap="2">
                  <Text fontSize='lg' color={textColor} fontFamily={theme.fonts.userAbout} fontWeight='bold'>   Comments :  </Text>
                  <Text fontSize='lg' color="gray.600" >  {commentData.length}</Text>
                </Flex>

                {commentData.filter(item => item.isActive === true).map((row, index, arr) => {

                  const date = moment(row.createdAt).fromNow();

                  return (
                    <>
                      <Flex direction={{ sm: "column", lg: "row" }} justify={{ sm: "center", lg: "space-between" }} align={{ sm: "center" }} w='100%' my={{ md: "12px" }}>

                        <Flex gap="2" align='left'>

                          <Avatar
                            size="sm"

                            cursor="pointer"

                            src={'http://ec2-34-201-174-68.compute-1.amazonaws.com:3000/' + row.user.image}
                          >
                          </Avatar>
                          <Flex direction={{ sm: "column", lg: "column" }} align='left'   >

                            <Flex align='left'>
                              <Text fontFamily={theme.fonts.userName}>    <Link href={'/#/auth/profile'} onClick={(e) => { sessionStorage.setItem("userId", row.userId); }}> {row.user !== null ? row.user.firstName : ""} - {date}  </Link>  </Text>
                            </Flex>

                            <Flex align='left' alignItems='center'>
                              <Text fontSize='md' me='10px'>  {row.comment}</Text>

                              {row.userId === auth ?
                                <>
                                  <Icon as={FaEdit} color='blue.400' fontSize='15px' me='6px' style={{ cursor: 'pointer' }} onClick={(e) => { EditComment.onOpen(); setComment(row); }} ></Icon>

                                  <Icon as={FaTrashAlt} color='red.400' fontSize='15px' me='6px' style={{ cursor: 'pointer' }} onClick={(e) => { DeleteComment.onOpen(); setComment(row); }} ></Icon>

                                </>
                                : ""}
                            </Flex>

                          </Flex>
                        </Flex>
                      </Flex>
                    </>
                  );
                })}
              </Grid>

            </Card>

          </>
          :

          <>
            <Card maxW='7xl' p="4" my={{ lg: "6px" }} ms={{ lg: "6px" }}  >

              <div className="loader"></div>

            </Card>
          </>
        }
      </Flex>

      <Modal
        size="4xl"
        isOpen={EditComment.isOpen}
        onClose={EditComment.onClose}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Edit Comment</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <form ref={EditCommentRef}>
              <FormControl mt={4}>
                <Input name='id' value={comment.id} type="hidden" />
                <Textarea name='comment' defaultValue={comment.comment} ></Textarea>
                <Button colorScheme='blue' onClick={commentEdit} style={{ marginTop: "10px", float: "right" }}><FaTelegramPlane /> Update</Button>
              </FormControl>
            </form>

          </ModalBody>
        </ModalContent>
      </Modal>

      <Modal
        size="2xl"
        initialFocusRef={DeleteCommentRef}
        finalFocusRef={DeleteCommentRef}
        isOpen={DeleteComment.isOpen}
        onClose={DeleteComment.onClose}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Delete Comment</ModalHeader>
          <ModalCloseButton />

          <ModalBody pb={6}>
            <form ref={DeleteComment}>

              <p>Are you sure want to delete? </p>

              <Stack direction='row' spacing={4} style={{ justifyContent: 'end' }}>
                <Button onClick={(e) => { CommentDelete(comment.id) }} colorScheme='red' variant='solid'> Yes </Button>
                <Button onClick={(e) => { DeleteComment.onClose() }} colorScheme='gray' variant='outline'>  No </Button>
              </Stack>

            </form>
          </ModalBody>
        </ModalContent>
      </Modal>

      <Modal onClose={onClose} size="md" isOpen={isOpen}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader> Login Required</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text> You have not Logged in, Please Login </Text>
          </ModalBody>
          <ModalFooter>
            <Flex gap="2">
              <Button colorScheme='blue' onClick={(e) => { history.push('auth/sigin'); }}> Signin</Button>
              <Button onClick={onClose}>Close</Button>
            </Flex>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Modal
        isOpen={Edit.isOpen}
        onClose={Edit.onClose}
        size="4xl"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader >Edit</ModalHeader>
          <ModalCloseButton />
          <form ref={EditForm}>
            <ModalBody pb={6}>

              <FormControl fontFamily={theme.fonts.message}> 
                <Input variant='auth' fontSize='sm' ms='4px' type='hidden' mb='24px' size='lg' defaultValue={edit.id}
                  name='id'
                />
                <Input variant='auth' fontSize='sm' ms='4px' type='text' mb='24px' size='lg' defaultValue={edit.title}
                  name='title'
                />

                <Textarea name='description' defaultValue={edit.description}  ></Textarea>


                <Select placeholder='Select category' name='category' mt={4} defaultValue={edit.category}>
                  {categoryData.map((row, index, arr) => {
                    return (
                      <option value={row.id}>{row.title}</option>
                    );
                  })}
                </Select>
              </FormControl>
            </ModalBody>

            <ModalFooter>
              <Button colorScheme='blue' mr={3} onClick={questionEdit}>   Save  </Button>
            </ModalFooter>

          </form>
        </ModalContent>
      </Modal>

    </>
  );
}

export default Article;
