import React, { Component, useEffect, useState, useRef, useReducer }  from 'react';
import {
    useDisclosure, 
    Avatar,
    Stack,
    Switch , 
    Badge,
    Button,
    Flex,
    Table,
    Tbody,
    Text,
    Th,
    Thead,
    Tr,
    Td,
    useColorModeValue, 

  FormControl,
  FormLabel,
  Input,
  Grid,
  Box,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
   } from "@chakra-ui/react";
 import { FaEdit } from "react-icons/fa"; 
 import { BsX } from "react-icons/bs"
  // Custom components
  import Card from "components/Card/Card.js";
  import CardBody from "components/Card/CardBody.js";
  import CardHeader from "components/Card/CardHeader.js";
  import axios from 'axios';
   import {  notification } from 'antd';
import { Category } from '@material-ui/icons';
import { MDBDataTable } from 'mdbreact';
import theme from 'theme/theme';


   const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      width: '35%',
    },
  };
  

  function Tables() {
    const textColor = useColorModeValue("gray.700", "white");
    const borderColor = useColorModeValue("gray.200", "gray.600");
    const server= sessionStorage.getItem("server");

    const token = sessionStorage.getItem("token");
   
    const initialRef = React.useRef(null)
    const finalRef = React.useRef(null)

    const [categoryData,setCategoryData] = useState([]);
    const [edit,setEdit] = useState([]);
    const [addcategory,setAddCategory] = useState([]);
    const Add = useDisclosure()
    const Edit = useDisclosure()
    const { isOpen, onOpen, onClose } = useDisclosure()
    
    const [reducerValue, forceUpdate] = useReducer(x => x + 1, 0);
    const [modalIsOpen, setIsOpen] = React.useState(false);
    const [addIsOpen, setAddIsOpen] = React.useState(false);

    function openModal() {
      setIsOpen(true);
    }
    function openAddModal() {
        setAddIsOpen(true);
      }
    function afterOpenModal() {
      // references are now sync'd and can be accessed.
      subtitle.style.color = '#f00';
    }
  
    function closeModal() {
      setIsOpen(false);
    }
    function closeAddModal() {
        setAddIsOpen(false);
      }

    const UserForm = useRef(null)
    const EditForm = useRef(null)

    const UserEdit = () => {
        const form = EditForm.current
                
            var data = JSON.stringify({
            "id":`${form['id'].value}`,
            "title":`${form['title'].value}`,
            }); 

            var config = {
            method: 'post',
            url: `${server}category/editCategory`,
            headers: { 
            'Authorization': token, 
            'Content-Type': 'application/json'
            },
            data : data
            };

            axios(config)
            .then(function (result) {

         
            if(result.data.status === true){
                Add.onClose();
                Edit.onClose();
              notification.success({ 
                top: '10%',
                placement: 'topRight',
                message: result.data.message, 
                onClose: () => {  
                    forceUpdate();
                },
            }); 
            } else {
              notification.error({ 
                top: '10%',
                placement: 'topRight',
                message: result.data.message, 
            }); 
            } 
      })
      .catch(function (error) {
        if(error.code === "ERR_NETWORK"){
          notification.error({ 
            top: '10%',
            placement: 'topRight',
            message: result.data.message,
        });
        } else {
          const msg = JSON.parse(error.request.response);  
          notification.error({ 
            top: '10%',
            placement: 'topRight',
            message: msg.message,
        });
        }
      });

     }

     const Usercategory = () => {
      const form = UserForm.current
              
          var data = JSON.stringify({
          "title":`${form['title'].value}`,
          }); 

          var config = {
          method: 'post',
          url: `${server}category/addCategory`,
          headers: { 
          'Authorization': token, 
          'Content-Type': 'application/json'
          },
          data : data
          };

          axios(config)
          .then(function (result) {
       
          if(result.data.status === true){
              Add.onClose();
            notification.success({ 
              top: '10%',
              placement: 'topRight',
              message: result.data.message, 
              onClose: () => {  
                  forceUpdate();
              },
          }); 
          } else {
            notification.error({ 
              top: '10%',
              placement: 'topRight',
              message: result.data.message, 
          }); 
          } 
    })
    .catch(function (error) {
      if(error.code === "ERR_NETWORK"){
        notification.error({ 
          top: '10%',
          placement: 'topRight',
          message: result.data.message,
      });
      } else {
        const msg = JSON.parse(error.request.response);  
        notification.error({ 
          top: '10%',
          placement: 'topRight',
          message: msg.message,
      });
      }
    });

   }
   

    useEffect(() => { 

        const fetchData = async () =>{

var data = JSON.stringify({}); 
var config = {
  method: 'post',
  url: `${server}auth/viewAllCategory`,
  headers: { 
    'Authorization': token, 
    'Content-Type': 'application/json'
  },
  data : data
};
axios(config)
.then(function (response) { 

  if(response.data.status === true){
    setCategoryData(response.data.data);

  }
})
.catch(function (error) {
  console.log(error);
}); 
}
fetchData()
  
  }, [reducerValue])

 

  const [usersForRender, setUsersForRender] = useState([]);

  
useEffect(() => {
  let postsArray = JSON.parse(JSON.stringify(categoryData));
  let categoryData1 = [];
  postsArray.map((item, index) => {
    const { id, title} = item;

    item.id = (
         <Text fontSize="sm" color={textColor} fontWeight="normal" >  {index+1}   </Text> 
    );
    item.title = (
      <Text  fontSize="sm" color={textColor} fontWeight="normal"  >  {title} </Text>
    );
 
    item.edit = (
      <Button colorScheme='twitter' size="sm" variant='outline' leftIcon={<FaEdit />} onClick={() => {Edit.onOpen(); setEdit(
        {
            "id": id,
            "title": title,
        }   );}}>    Edit    </Button> 


    );

    item.action = (
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div
          className="uil-trash-alt"
          style={{
            cursor: "pointer",
            color: "black",
            fontSize: ".7em",
            padding: ".5rem",
            borderRadius: ".3rem",
            background: "#fb6262",
          }}
          onClick={() => deletePost(posts[index].id)}
        >
          Delete
        </div>
      </div>
    );
    categoryData1.push(item);
  });
  setUsersForRender(categoryData1);
}, [categoryData]);


 
const data = {
  columns: [
    {
      label: "S.No",
      field: "id",
      sort: "asc",
      width: 150,
    },
    {
      label: "Category",
      field: "title",
      sort: "asc",
      width: 270,
    },

    {
      label: "Edit",
      field: "edit",
      sort: "asc",
      width: 200,
    },
  ],
  rows: usersForRender
};


    return (
        <>
      <Flex direction="column" pt={{ base: "120px", md: "75px" }}>
        <Card overflowX={{ sm: "scroll", xl: "hidden" }} pb="0px">
          <CardHeader p="6px 0px 22px 0px" style={{display:"flex",justifyContent:"space-between"}}>
            <Text fontSize="xl" color={textColor} fontWeight="light" fontFamily={theme.fonts.userDetails}>Category List</Text>
            <Button type="button" className="button-btn" fontFamily={theme.fonts.button} onClick={Add.onOpen} style={{width: '15%'}}>Add</Button>
          </CardHeader>
          <CardBody fontFamily={theme.fonts.message} fontSize="15px">
           {/**  <Table variant="simple" color={textColor}>
              <Thead>
                <Tr my=".8rem" pl="0px" color="gray.400" >
                <Th pl="0px" borderColor={borderColor} color="gray.400" > S.No </Th> 
                  <Th pl="0px" borderColor={borderColor} color="gray.400" > Category </Th> 
                  <Th borderColor={borderColor}> Edit</Th>
                </Tr>
              </Thead>
              <Tbody>
                {categoryData.map((row, index, arr) => {
                      const { id, title } = row;
                      const textColor = useColorModeValue("gray.500", "white");
                      const titleColor = useColorModeValue("gray.700", "white");
                      const bgStatus = useColorModeValue("gray.400", "navy.900");
                      const borderColor = useColorModeValue("gray.200", "gray.600");
                  return (
                    <Tr>
                            <Td borderColor={borderColor}  >
                     
                                <Text fontSize="md" color={textColor} fontWeight="normal" >  {index+1}   </Text> 
                  
                            </Td>
                            <Td borderColor={borderColor}  >
                     
                                <Text fontSize="md" color={textColor} fontWeight="normal" > {title}  </Text> 
                
                            </Td>
                    
                    <Td borderColor={borderColor}  >

                    <Button colorScheme='twitter' variant='outline' leftIcon={<FaEdit />} onClick={() => {Edit.onOpen(); setEdit(
                        {
                            "id": id,
                            "title": title,
                        }   );}}>    Edit    </Button> 
                  
                    </Td>
                  </Tr>
                  
                  );
                })}
              </Tbody>
            </Table>
             */}
            <MDBDataTable
               className='usertable'
       data={data}
       searching={true}
       paging={true}
       info={true}
     />
          </CardBody>
        </Card>
       
      </Flex>

 
      <Modal
        initialFocusRef={initialRef}
        finalFocusRef={finalRef}
        isOpen={Edit.isOpen}
        onClose={Edit.onClose}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader >Edit Category</ModalHeader>
          <ModalCloseButton />
          <form ref={EditForm}>
            <ModalBody pb={6}>
        
            <FormControl>
              <FormLabel>Title</FormLabel>
              <Input variant='auth' fontSize='md' ms='4px' type='hidden' mb='24px' size='lg' defaultValue={edit.id}
             name='id'
            /> 
              <Input variant='auth' fontSize='md'  ms='4px' type='text' placeholder='Category' mb='24px' size='lg' defaultValue={edit.title}
              name='title'
            />
            </FormControl>
          </ModalBody>

          <ModalFooter>
            <Button colorScheme='blue' mr={3} onClick={UserEdit}>
              Save
            </Button>
          </ModalFooter>
   
          </form>
        </ModalContent>
      </Modal>


      <Modal
        initialFocusRef={initialRef}
        finalFocusRef={finalRef}
        isOpen={Add.isOpen}
        onClose={Add.onClose}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Add Category</ModalHeader>
          <ModalCloseButton />
          <form ref={UserForm}>
            <ModalBody pb={6}>
        
            <FormControl>
              <FormLabel>Title</FormLabel>
              <Input ref={initialRef} placeholder=''  name='title'/>
            </FormControl>
          </ModalBody>

          <ModalFooter>
            <Button colorScheme='blue' mr={3} onClick={Usercategory}>
              Save
            </Button>
          </ModalFooter>
   
          </form>
        </ModalContent>
      </Modal>
    
</>
    );
  }
  
  export default Tables;
  