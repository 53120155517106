import React, { Component, useEffect, useState, useRef, useReducer } from 'react';
import {
  useDisclosure,
  Avatar,
  Stack,
  Switch,
  Badge,
  Button,
  Flex,
  Table,
  Tbody,
  Text,
  Th,
  Thead,
  Tr,
  Td,
  useColorModeValue,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  FormControl,
  FormLabel,
  Input,
  Grid,
  Box,
} from "@chakra-ui/react";
import { FaEdit } from "react-icons/fa";
// Custom components
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import axios from 'axios';
import { notification } from 'antd';
import moment from "moment";
import { MDBDataTable } from 'mdbreact';
import theme from 'theme/theme';

function Tables() {
  const textColor = useColorModeValue("gray.700", "white");
  const borderColor = useColorModeValue("gray.200", "gray.600");
  const server= sessionStorage.getItem("server");
  const token = sessionStorage.getItem("token");


  const [userData, setUserData] = useState([]);
  const [edit, setEdit] = useState([]);

  const { isOpen, onOpen, onClose } = useDisclosure()

  const [reducerValue, forceUpdate] = useReducer(x => x + 1, 0);


  const UserForm = useRef(null)


  const UserEdit = () => {
    const form = UserForm.current

    var data = JSON.stringify({
      "id": `${form['id'].value}`,
      "firstName": `${form['firstName'].value}`,
      "lastName": `${form['lastName'].value}`,
      "companyName": `${form['companyName'].value}`,
      "mobile": `${form['mobile'].value}`
    });

    var config = {
      method: 'post',
      url: `${server}admin/editUser`,
      headers: {
        'Authorization': token,
        'Content-Type': 'application/json'
      },
      data: data
    };

    axios(config)
      .then(function (result) {


        if (result.data.status === true) {
          onClose();
          notification.success({
            top: '10%',
            placement: 'topRight',
            message: result.data.message,
            onClose: () => {
              forceUpdate();
            },
          });
        } else {
          notification.error({
            top: '10%',
            placement: 'topRight',
            message: result.data.message,
          });
        }
      })
      .catch(function (error) {
        if (error.code === "ERR_NETWORK") {
          notification.error({
            top: '10%',
            placement: 'topRight',
            message: result.data.message,
          });
        } else {
          const msg = JSON.parse(error.request.response);
          notification.error({
            top: '10%',
            placement: 'topRight',
            message: msg.message,
          });
        }
      });

  }

  const [usersForRender, setUsersForRender] = useState([]);

  useEffect(() => {

    const fetchData = async () => {

      var data = JSON.stringify({});
      var config = {
        method: 'post',
        url: `${server}admin/viewAllusers`,
        headers: {
          'Authorization': token,
          'Content-Type': 'application/json'
        },
        data: data
      };

      axios(config)
        .then(function (response) {
          setUserData(response.data.data);
        })
        .catch(function (error) {
          console.log(error);
        });
    }
    fetchData()

  }, [reducerValue])


  function status(id) {

    var data = JSON.stringify({ id: id });
    var config = {
      method: 'post',
      url: `${server}admin/changeUserActivity`,
      headers: {
        'Authorization': token,
        'Content-Type': 'application/json'
      },
      data: data
    };

    axios(config)
      .then(function (response) {


      })
      .catch(function (error) {
        console.log(error);
      });
  }


  useEffect(() => {
    let postsArray = JSON.parse(JSON.stringify(userData));
    let userData1 = [];
    postsArray.map((item, index) => {
      const { id, firstName, email, lastName, companyName, role, mobile, createdAt, isActive } = item;

      item.id = (
        <Text fontSize="sm" color={textColor} fontWeight="normal" >  {index + 1}   </Text>
      );
      item.name = (
        <Text fontSize="sm" color={textColor} fontWeight="normal"  >   {firstName}   {lastName}  </Text>
      );

      item.email = (
        <Text fontSize="sm" color={textColor} fontWeight="normal" >   {email}  </Text>

      );
      item.companyName = (
        <Text fontSize="sm" color={textColor} fontWeight="normal">  {companyName} </Text>
      );
      item.role = (
        <Text fontSize="sm" color={textColor} fontWeight="normal">    {role}  </Text>

      );
      item.status = (
        <Stack align="center" direction="row"> <Switch size="md" defaultChecked={isActive === true ? true : false} onChange={() => { status(id); }} /> </Stack>

      );

      item.created = (
        <Text fontSize="sm" color={textColor} fontWeight="normal">     {moment(createdAt).format("DD-MM-YYYY hh:mm")}   </Text>
      );

      item.edit = (
        <Button colorScheme='twitter' variant='outline' leftIcon={<FaEdit />} onClick={() => {
          onOpen(); setEdit(
            {
              "id": id,
              "firstName": firstName,
              "lastName": lastName,
              "companyName": companyName,
              "role": role,
              "mobile": mobile,
              "createdAt": createdAt,
              "isActive": isActive
            }
          );
        }}>
          Edit
        </Button>
      );

      item.action = (
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div
            className="uil-trash-alt"
            style={{
              cursor: "pointer",
              color: "black",
              fontSize: ".7em",
              padding: ".5rem",
              borderRadius: ".3rem",
              background: "#fb6262",
            }}
            onClick={() => deletePost(posts[index].id)}
          >
            Delete
          </div>
        </div>
      );
      userData1.push(item);
    });
    setUsersForRender(userData1);
  }, [userData]);


  const data = {
    columns: [
      {
        label: "S.No",
        field: "id",
        sort: "asc",
        width: 150,
      },
      {
        label: "Name",
        field: "name",
        sort: "asc",
        width: 270,
      },

      {
        label: "Email",
        field: "email",
        sort: "asc",
        width: 200,
      },

      {
        label: "Mobile",
        field: "mobile",
        sort: "asc",
        width: 200,
      },


      {
        label: "Company Name",
        field: "companyName",
        sort: "asc",
        width: 270,
      },

      {
        label: "Role",
        field: "role",
        sort: "asc",
        width: 200,
      },

      {
        label: "Status",
        field: "status",
        sort: "asc",
        width: 200,
      },

      {
        label: "Created",
        field: "created",
        sort: "asc",
        width: 200,
      },
      {
        label: "Edit",
        field: "edit",
        sort: "asc",
        width: 200,
      },
    ],
    rows: usersForRender
  };
  return (


    <>
      <Flex direction="column" pt={{ base: "120px", md: "75px" }}>
        <Card overflowX="scroll" pb="0px">
          <CardHeader p="6px 0px 22px 0px">
            <Text fontSize="xl" color={textColor} fontFamily={theme.fonts.userDetails}> Users List  </Text>
            <Text fontSize="xl" color={textColor} fontWeight="bold"> </Text>
          </CardHeader>
          <CardBody fontFamily={theme.fonts.message} fontSize='15px'>

            { /**  <Table variant="simple" id="" color={textColor} overflowX="scroll">
              <Thead>
                <Tr my=".8rem" pl="0px" color="gray.400" >
                <Th borderColor={borderColor} color="gray.400" > S.No </Th> 
                  <Th  borderColor={borderColor} color="gray.400" > Name </Th> 
                  <Th borderColor={borderColor} color="gray.400" >Email</Th>
                  <Th borderColor={borderColor} color="gray.400" >Mobile</Th>
                  <Th borderColor={borderColor} color="gray.400" >Company Name</Th>
                  <Th borderColor={borderColor} color="gray.400" >Role</Th>
                  <Th borderColor={borderColor} color="gray.400" >Status</Th>
                  <Th borderColor={borderColor} color="gray.400" >Created</Th>
                  <Th borderColor={borderColor}> Edit</Th>
                </Tr>
              </Thead>
              <Tbody >
                {userData.map((row, index, arr) => {
                      const { id, firstName, email, lastName, companyName, role, mobile, createdAt, isActive } = row;
                      const textColor = useColorModeValue("gray.500", "white");
                      const titleColor = useColorModeValue("gray.700", "white");
                      const bgStatus = useColorModeValue("gray.400", "navy.900");
                      const borderColor = useColorModeValue("gray.200", "gray.600");
                  return (
                    <Tr>
                     <Td borderColor={borderColor}  >  <Text fontSize="md" color={textColor} fontWeight="normal" >  {index+1}   </Text>  </Td>
                    <Td  borderColor={borderColor}  >  <Text  fontSize="md" color={textColor} fontWeight="normal"  >   {firstName}   {lastName}  </Text>    </Td>
              
                    <Td borderColor={borderColor}  >  <Text fontSize="md" color={textColor} fontWeight="normal" >   {email}  </Text>   </Td>

                    <Td borderColor={borderColor}  >  <Text fontSize="md" color={textColor} fontWeight="normal" >   {mobile}   </Text>    </Td> 

                    <Td borderColor={borderColor}  >   <Text fontSize="md" color={textColor}  fontWeight="normal">  {companyName} </Text>  </Td>
                    <Td borderColor={borderColor}  >   <Text fontSize="md" color={textColor}  fontWeight="normal">    {role}  </Text> </Td>
                    <Td borderColor={borderColor}  >   <Stack align="center" direction="row"> <Switch size="md"  defaultChecked={isActive === true ? true : false}  onChange={() => { status(id);  }}  /> </Stack> </Td>
                    <Td borderColor={borderColor}  >   <Text fontSize="md" color={textColor}  fontWeight="normal">     {moment(createdAt).format("DD-MM-YYYY hh:mm")}   </Text>   </Td> 
                    <Td borderColor={borderColor}  >

                      <Button colorScheme='twitter' variant='outline' leftIcon={<FaEdit />} onClick={() => {onOpen(); setEdit(
                        {
                            "id": id,
                            "firstName": firstName,
                            "lastName": lastName,
                            "companyName": companyName, 
                            "role": role,
                            "mobile": mobile,
                            "createdAt": createdAt, 
                            "isActive": isActive
                        }
                    );}}>
                        Edit
                      </Button>

                    </Td>
                  </Tr>
                  
                  );
                })}
              </Tbody>
            </Table> 
              */}

            <MDBDataTable
              className='usertable'
              data={data}
              searching={true}
              paging={true}
              info={true}
            />

          </CardBody>
        </Card>

      </Flex>


      <Modal size="4xl" isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader fontFamily={theme.fonts.userDetails}>User Edit</ModalHeader>
          <ModalCloseButton />
          <form ref={UserForm}>
            <ModalBody pb={6}>


              <Input
                variant='auth'
                fontSize='sm'
                ms='4px'
                type='hidden'
                mb='24px'
                size='lg'
                defaultValue={edit.id}
                name='id'
              />
              <Grid templateColumns="repeat(2, 1fr)" gap={3}>
                <Box  > <FormLabel ms='4px' fontSize='sm' fontWeight='bold' fontFamily={theme.fonts.userAbout}>  First Name   </FormLabel>
                  <Input
                    variant='auth'
                    fontSize='sm'
                    ms='4px'
                    type='text'
                    placeholder='Your First Name'
                    mb='24px'
                    size='lg'
                    defaultValue={edit.firstName}
                    name='firstName'
                    fontFamily={theme.fonts.message}
                  /> </Box>
                <Box    > <FormLabel ms='4px' fontSize='sm' fontWeight='bold' fontFamily={theme.fonts.userAbout}>Last Name </FormLabel>
                  <Input
                    variant='auth'
                    fontSize='sm'
                    ms='4px'
                    type='text'
                    placeholder='Your Last Name'
                    mb='24px'
                    size='lg'
                    defaultValue={edit.lastName}
                    name='lastName'
                    fontFamily={theme.fonts.message}
                  /> </Box>
              </Grid>

              <Grid templateColumns="repeat(2, 1fr)" gap={3}>
                <Box  > <FormLabel ms='4px' fontSize='sm' fontWeight='bold' fontFamily={theme.fonts.userAbout}>  Company Name  </FormLabel>
                  <Input
                    variant='auth'
                    fontSize='sm'
                    ms='4px'
                    type='text'
                    placeholder='Your Company Name'
                    mb='24px'
                    size='lg'
                    defaultValue={edit.companyName}
                    name='companyName'
                    fontFamily={theme.fonts.message}
                  /> </Box>
                <Box> <FormLabel ms='4px' fontSize='sm' fontWeight='bold' fontFamily={theme.fonts.userAbout}> Mobile </FormLabel>
                  <Input
                    variant='auth'
                    fontSize='sm'
                    ms='4px'
                    type='number'
                    placeholder='Your Mobile'
                    mb='24px'
                    size='lg'
                    defaultValue={edit.mobile}
                    name='mobile'
                    fontFamily={theme.fonts.message}
                  /> </Box>
              </Grid>


            </ModalBody>
            <ModalFooter>
              <Button colorScheme="blue" mr={2} onClick={UserEdit} fontFamily={theme.fonts.button} fontSize="sm">
                Save
              </Button>
              <Button fontFamily={theme.fonts.button} onClick={onClose} fontSize="sm">Cancel</Button>
            </ModalFooter>

          </form>
        </ModalContent>

      </Modal>
    </>
  );
}

export default Tables;
