// import
import React, { Component }  from 'react';
import Dashboard from "views/Dashboard/Dashboard.js";
import Users from "views/Dashboard/Users.js";
import Category from "views/Dashboard/Category";
import Questions from "views/Dashboard/Questions.js";
import Tables from "views/Dashboard/Tables.js";
import Home from "views/Pages/Home.js";
import Home1 from "views/Pages/Home1.js";

import Search from "views/Pages/Search.js";
import Profile from "views/Pages/Profile.js";
import Myprofile from "views/Pages/Myprofile.js";
import Article from "views/Pages/Article";
import SignIn from "views/Pages/SignIn.js";
import SignUp from "views/Pages/SignUp.js";
import Forget from "views/Pages/Forget.js";
import Verify from "views/Pages/Verify.js";


import {
  HomeIcon,
  StatsIcon,
  CreditIcon,
  PersonIcon,
  DocumentIcon,
  RocketIcon,
  SupportIcon,
} from "components/Icons/Icons";

import { FaQuestion } from "react-icons/fa"  


var dashRoutes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    rtlName: "لوحة القيادة",
    icon: <HomeIcon color='inherit' />,
    component: Dashboard,
    layout: "/admin",
  },
  {
    path: "/tables",
    name: "Tables",
    rtlName: "لوحة القيادة",
    icon: <StatsIcon color='inherit' />,
    component: Tables,
    layout: "/admin",
  },
  {
    path: "/users",
    name: "Users",
    rtlName: "لوحة القيادة",
    icon: <StatsIcon color='inherit' />,
    component: Users,
    layout: "/admin",
  },
  {
    path: "/questions",
    name: "Questions",
    rtlName: "لوحة القيادة",
    icon: <FaQuestion color='inherit' />,
    component: Questions,
    layout: "/admin",
  },
  {
    path: "/category",
    name: "Category",
    rtlName: "لوحة القيادة",
    icon: <FaQuestion color='inherit' />,
    component: Category,
    layout: "/admin",
  },
 
  {
    path: "/home",
    name: "Home",
    rtlName: "لوحة القيادة",
    icon: <CreditIcon color='inherit' />,
    component: Home,
    layout: "/auth",
  }, 
  {
    path: "/home1",
    name: "Home1",
    rtlName: "لوحة القيادة",
    icon: <CreditIcon color='inherit' />,
    component: Home1,
    layout: "/auth",
  }, 
  {
    path: "/forget/:id",
    name: "Forget",
    rtlName: "لوحة القيادة",
    icon: <PersonIcon color='inherit' />,
    secondaryNavbar: true,
    component: Forget,
    layout: "/auth",
  },
  {
    path: "/verify/:id",
    name: "Verify",
    rtlName: "لوحة القيادة",
    icon: <PersonIcon color='inherit' />,
    secondaryNavbar: true,
    component: Verify,
    layout: "/auth",
  },
  {
    path: "/profile",
    name: "Profile",
    rtlName: "لوحة القيادة",
    icon: <PersonIcon color='inherit' />,
    secondaryNavbar: true,
    component: Profile,
    layout: "/auth",
  },
  {
    path: "/myprofile",
    name: "Myprofile",
    rtlName: "لوحة القيادة",
    icon: <PersonIcon color='inherit' />,
    secondaryNavbar: true,
    component: Myprofile,
    layout: "/auth",
  },
  {
    path: "/article",
    name: "Article",
    rtlName: "لوحة القيادة",
    icon: <CreditIcon color='inherit' />,
    component: Article,
    layout: "/auth",
  },
  {
    path: "/search",
    name: "Search",
    rtlName: "لوحة القيادة",
    icon: <CreditIcon color='inherit' />,
    component: Search,
    layout: "/auth",
  },
  {
    path: "/signin",
    name: "Sign In",
    rtlName: "لوحة القيادة",
    icon: <DocumentIcon color='inherit' />,
    component: SignIn,
    layout: "/auth",
  },
  {
    path: "/signup",
    name: "Sign Up",
    rtlName: "لوحة القيادة",
    icon: <RocketIcon color='inherit' />,
    component: SignUp,
    layout: "/auth",
  },
];
export default dashRoutes;
