import React, { useState } from "react";
import "../../assets/css/signup.css";
// Chakra imports
import {
  Box,
  Flex,
  Button,
  FormControl,
  FormLabel,
  HStack,
  Input,
  Icon,
  Link,
  Switch,
  Text,
  useColorModeValue,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  FormErrorMessage,
} from "@chakra-ui/react";
// Assets
import signInImage from "assets/img/signInImage.png";
import { FaLinkedin, FaFacebook, FaGoogle } from "react-icons/fa";
import axios from "axios";
import { notification } from "antd";

import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import theme from "theme/theme";

function SignIn() {
  const server = "https://23.20.212.243:8080/api/";
  // const server= "http://ec2-34-201-174-68.compute-1.amazonaws.com:3000/api/";

  const textColor = useColorModeValue("gray.600", "white");
  const bgForm = useColorModeValue("white", "navy.800");
  const titleColor = useColorModeValue("gray.700", "blue.500");
  const colorIcons = useColorModeValue("gray.700", "white");
  const bgIcons = useColorModeValue("trasnparent", "navy.700");
  const bgIconsHover = useColorModeValue("gray.50", "whiteAlpha.100");

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const history = useHistory();

  const Forget = useDisclosure();

  const { isOpen, onOpen, onClose } = useDisclosure();

  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting },
  } = useForm();

  const {
    register: register2,
    formState: { errors: errors2, isSubmitting: isSubmitting2 },
    handleSubmit: handleSubmit2,
  } = useForm({
    mode: "onBlur",
  });

  function onLogin(values) {
    return new Promise((resolve) => {
      axios
        .post(`${server}auth/login`, {
          email: values.email,
          password: values.password,
        })
        .then((result) => {
          resolve();

          if (result.data.status === true) {
            sessionStorage.setItem("token", result.data.token);
            sessionStorage.setItem("server", server);

            history.push("/admin/dashboard");
          } else if (result.data.status === false) {
            if (result.data.verify === true) {
              notification.error({
                top: "10%",
                placement: "topRight",
                message: result.data.message,
                onClose: () => {
                  resolve();
                },
              });
            } else {
              onOpen();
            }
          }
          else {
            console.log('nothing')
          }
        })
        .catch(function (error) {
          console.log(error);

          // if (error.code === "ERR_NETWORK") {
          //   notification.error({
          //     top: "10%",
          //     placement: "topRight",
          //     message: result.data.message,
          //   });
          // } else {
          //   const msg = JSON.parse(error.request.response);
          //   notification.error({
          //     top: "10%",
          //     placement: "topRight",
          //     message: msg.message,
          //   });
          // }
        });
    });
  }

  function onSubmit(values) {
    return new Promise((resolve) => {
      var data = JSON.stringify({
        email: values.email,
      });

      var config = {
        method: "post",
        url: `${server}auth/forgetPassword`,
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };

      axios(config).then(function (result) {
        if (result.data.status === true) {
          Forget.onClose();

          notification.success({
            top: "10%",
            placement: "topRight",
            message: result.data.message,
            onClose: () => {
              resolve();
            },
          });
        }
      });
    });
  }

  function resend() {
    var data = JSON.stringify({
      email: email,
    });
    var config = {
      method: "post",
      url: `${server}auth/resendVerifyEmail`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        if (response.data.status === true) {
          notification.success({
            top: "10%",
            placement: "topRight",
            message: response.data.message,
            onClose: () => {
              onClose();
              resolve();
            },
          });
        }
      })

      .catch(function (error) {
        console.log(error);
      });
  }
  return (
    <Flex align='center' justify='center' className='bg-img'>
      <Flex
        minH={{ md: "96vh" }}
        h={{ sm: "initial", md: "75vh", lg: "85vh" }}
        w='100%'
        maxW='1044px'
        mx='auto'
        justifyContent='space-between'
        mb='30px'
        pt={{ md: "0px" }}
      >
        <Flex
          w='100%'
          h='100%'
          align='center'
          justify='center'
          mb='60px'
          mt={{ base: "50px", md: "20px" }}
        >
          <Flex
            zIndex='2'
            direction='column'
            w='445px'
            gap='15px'
            background='transparent'
            borderRadius='15px'
            p='40px'
            mx={{ base: "100px" }}
            m={{ base: "20px", md: "auto" }}
            bg={bgForm}
            boxShadow={useColorModeValue(
              "0px 5px 14px rgba(0, 0, 0, 0.05)",
              "unset"
            )}
          >
            <Text
              fontSize='xl'
              color={textColor}
              textAlign='center'
              fontFamily={theme.fonts.userDetails}
            >
              Login With
            </Text>
            <HStack spacing='15px' justify='center' align='center'>
              <Flex justify='center' align='center'>
                <Link
                  display='flex'
                  justifyContent='center'
                  alignItems='center'
                  href='#'
                >
                  <Icon
                    as={FaFacebook}
                    color={"gray.600"}
                    w='40px'
                    h='40px'
                    _hover={{ color: "blue.50", bg: "blue.500" }}
                    borderRadius='8px'
                    border={useColorModeValue("1px solid", "0px")}
                    borderColor='gray.200'
                    cursor='pointer'
                    transition='all .25s ease'
                    p='2'
                  />
                </Link>
              </Flex>
              <Flex justify='center' align='center'>
                <Link
                  display='flex'
                  justifyContent='center'
                  alignItems='center'
                  href='#'
                >
                  <Icon
                    as={FaLinkedin}
                    color={colorIcons}
                    w='40px'
                    h='40px'
                    _hover={{ color: "blue.50", bg: "blue.700" }}
                    borderRadius='8px'
                    border={useColorModeValue("1px solid", "0px")}
                    borderColor='gray.200'
                    cursor='pointer'
                    transition='all .25s ease'
                    p='2'
                  />
                </Link>
              </Flex>
              <Flex justify='center' align='center'>
                <Link
                  display='flex'
                  justifyContent='center'
                  alignItems='center'
                  href='#'
                >
                  <Icon
                    as={FaGoogle}
                    color={colorIcons}
                    w='40px'
                    h='40px'
                    _hover={{ color: "orange.50", bg: "orange.500" }}
                    borderRadius='8px'
                    border={useColorModeValue("1px solid", "0px")}
                    borderColor='gray.200'
                    cursor='pointer'
                    transition='all .25s ease'
                    p='2'
                  />
                </Link>
              </Flex>
            </HStack>
            <Text
              fontSize='lg'
              color='gray.400'
              fontWeight='bold'
              textAlign='center'
            >
              or
            </Text>

            <form onSubmit={handleSubmit2(onLogin)}>
              <FormControl isInvalid={errors2.email} mt={4}>
                <FormLabel
                  htmlFor='email'
                  fontFamily={theme.fonts.userAbout}
                  fontSize='sm'
                  fontWeight={"bold"}
                >
                  Email
                </FormLabel>
                <Input
                  type='text'
                  id='email'
                  placeholder='Your Email-Id'
                  fontFamily={theme.fonts.message}
                  fontSize='sm'
                  {...register2("email", {
                    required: "Email is required",
                  })}
                  onChange={(event) => setEmail(event.currentTarget.value)}
                />
                <FormErrorMessage>
                  {errors2.email && errors2.email.message}
                </FormErrorMessage>
              </FormControl>

              <FormControl isInvalid={errors2.password} mt={4}>
                <FormLabel
                  htmlFor='password'
                  fontFamily={theme.fonts.userAbout}
                  fontSize='sm'
                  fontWeight={"bold"}
                >
                  Password
                </FormLabel>
                <Input
                  type='password'
                  id='password'
                  placeholder='Your password'
                  fontFamily={theme.fonts.message}
                  fontSize='sm'
                  {...register2("password", {
                    required: "Password is required",
                  })}
                  onChange={(event) => setPassword(event.currentTarget.value)}
                />
                <FormErrorMessage>
                  {errors2.password && errors2.password.message}
                </FormErrorMessage>
              </FormControl>

              <Flex
                flexDirection='column'
                justifyContent='center'
                alignItems='center'
                maxW='100%'
                mt={4}
              >
                <Button
                  color='white'
                  borderRadius='30px'
                  backgroundColor='gray.600'
                  mx='6px'
                  px='8'
                  _hover={{ color: "gray.600", bg: "gray.50" }}
                  fontFamily={theme.fonts.button}
                  fontSize='sm'
                  isLoading={isSubmitting2}
                  type='submit'
                >
                  {" "}
                  Login{" "}
                </Button>
              </Flex>
            </form>
            <Flex
              flexDirection='column'
              justifyContent='center'
              alignItems='center'
              maxW='100%'
              mt='0px'
            >
              <Text
                color={textColor}
                fontWeight='medium'
                onClick={(e) => {
                  Forget.onOpen();
                }}
                style={{ cursor: "pointer" }}
                fontFamily={theme.fonts.message}
                fontSize='md'
              >
                {" "}
                Forgot password?{" "}
              </Text>
            </Flex>

            <Flex
              flexDirection='column'
              justifyContent='center'
              alignItems='center'
              maxW='100%'
              mt='0px'
            >
              <Text
                color={textColor}
                fontWeight='medium'
                fontFamily={theme.fonts.message}
                fontSize='md'
              >
                Do have an account?
                <Link
                  color='orange.500'
                  ms='5px'
                  href='#/auth/signup'
                  fontWeight='bold'
                >
                  Sign Up
                </Link>
              </Text>
            </Flex>
          </Flex>
          {/* Modal Open Popup */}
          <Modal size='2xl' isOpen={Forget.isOpen} onClose={Forget.onClose}>
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>Forget Password</ModalHeader>
              <ModalCloseButton />

              <ModalBody pb={6}>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <FormControl isInvalid={errors.email}>
                    <FormLabel htmlFor='email'>Email</FormLabel>
                    <Input
                      id='email'
                      placeholder='Enter Your Email-Id'
                      {...register("email", {
                        required: "Email is required",
                      })}
                      onChange={(event) => setEmail(event.currentTarget.value)}
                    />
                    <FormErrorMessage>
                      {errors.email && errors.email.message}
                    </FormErrorMessage>
                  </FormControl>
                  <Button
                    mt={4}
                    colorScheme='teal'
                    isLoading={isSubmitting}
                    type='submit'
                  >
                    Submit
                  </Button>
                </form>
              </ModalBody>
            </ModalContent>
          </Modal>
          <Flex justify={"center"} align={"center"}>
            <Modal onClose={onClose} size='sm' isOpen={isOpen}>
              <ModalOverlay />
              <ModalContent top='25%'>
                <ModalHeader fontFamily={theme.fonts.userDetails} fontSize='md'>
                  {" "}
                  Action Required
                </ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                  <Text fontFamily={theme.fonts.message} fontSize='sm'>
                    {" "}
                    You have Not Verified the Email, Click Resend to Send the
                    Verify Email
                  </Text>
                </ModalBody>
                <ModalFooter>
                  <Flex gap='2'>
                    <Button
                      colorScheme='blue'
                      fontFamily={theme.fonts.button}
                      fontSize='sm'
                      onClick={(e) => {
                        resend();
                      }}
                    >
                      {" "}
                      Resend
                    </Button>
                    <Button
                      fontFamily={theme.fonts.button}
                      fontSize='sm'
                      onClick={onClose}
                    >
                      Close
                    </Button>
                  </Flex>
                </ModalFooter>
              </ModalContent>
            </Modal>
          </Flex>
        </Flex>
        <Box
          overflowX='hidden'
          overflowY='hidden'
          h='100%'
          w='100%'
          left='0px'
          position='absolute'
        >
          <Box
            w='100%'
            h='100%'
            bgSize='cover'
            bg='gray.600'
            opacity='0.7'
          ></Box>
        </Box>
      </Flex>
    </Flex>
  );
}

export default SignIn;
