// Chakra Icons
import React, { useEffect, useRef, useState, useReducer } from 'react';

// Chakra Imports
import {
  useDisclosure,
  Box, Button,
  Flex,
  Icon,
  Menu,
  MenuButton,
  MenuItem,
  MenuList, Stack, Text, useColorMode,
  useColorModeValue,
  IconButton,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  FormControl,
  FormLabel,
  Input,
  Textarea,
  Select,
  Link,
  FormErrorMessage,
  VStack,
  HStack,
  Badge,
  Grid,
  Heading,
  Avatar, AvatarBadge,
  Divider,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  useBreakpointValue
} from "@chakra-ui/react";
import {
  FaUserCircle,
  FaMoon,
  FaSignOutAlt,
  FaUserEdit,
  FaUserAlt,
  FaUserPlus,
  FaUserCheck,
  FaEdit,
  FaHome
} from "react-icons/fa";
import { SearchIcon, CloseIcon } from "@chakra-ui/icons";

import {
  MdPassword
} from "react-icons/md";
import { AiFillFire } from "react-icons/ai"

import axios from 'axios';
import { notification } from 'antd';
// Custom Icons
import { ArgonLogoDark, ArgonLogoLight, ChakraLogoDark, ChakraLogoLight, ProfileIcon, SettingsIcon } from "components/Icons/Icons";
// Custom Components
import { SearchBar } from "components/Forum_Navbar/SearchBar/SearchBar";

import jwt_decode from "jwt-decode";
import { useHistory } from 'react-router-dom';

import { useForm } from 'react-hook-form';

import { VscDeviceCamera } from "react-icons/vsc";

import { BsFillQuestionDiamondFill } from "react-icons/bs";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import theme from 'theme/theme';


export default function HeaderLinks(props) {
  const {
    variant,
    children,
    fixed,
    scrolled,
    secondary,

    sidebarVariant,
    setSidebarVariant,

    ...rest
  } = props;

  const { colorMode, toggleColorMode } = useColorMode();
  const [switched, setSwitched] = useState(props.isChecked);

  const { isOpen, onOpen, onClose } = useDisclosure();

  let bgButton = useColorModeValue(
    "linear-gradient(81.62deg, #313860 2.25%, #151928 79.87%)",
    "white"
  );
  let colorButton = useColorModeValue("white", "gray.700");
  const secondaryButtonBg = useColorModeValue("white", "transparent");
  const secondaryButtonBorder = useColorModeValue("gray.700", "white");
  const secondaryButtonColor = useColorModeValue("gray.700", "white");
  const bgDrawer = useColorModeValue("white", "navy.800");

  const settingsRef = React.useRef();

  // Chakra Color Mode
  let navbarIcon =
    fixed && scrolled
      ? useColorModeValue("gray.700", "gray.200")
      : useColorModeValue("white", "gray.200");
  let menuBg = useColorModeValue("white", "navy.800");
  if (secondary) {
    navbarIcon = "white";
  }

  const [auth, setAuth] = useState([]);

  const token = sessionStorage.getItem("token");

  const server= "https://23.20.212.243:8080/api/";
  //  const server= "http://ec2-34-201-174-68.compute-1.amazonaws.com:3000/api/";

  const history = useHistory();

  const Ask = useDisclosure();
  const Edit = useDisclosure();
  const Change = useDisclosure();

  const View = useDisclosure();
  const [search, setSearch] = useState("");

  const [categoryList, setCategoryList] = useState([]);

  useEffect(() => {


    if (token !== null) {

      var data = JSON.stringify({});
      var config = {
        method: 'post',
        url: `${server}user/getUser`,
        headers: {
          'Authorization': token,
          'Content-Type': 'application/json'
        },
        data: data

      };

      axios(config)
        .then(function (response) {

          if (response.data.status === true) {

            setAuth(response.data.data);

            var data1 = JSON.stringify({
              id: response.data.data.id
            });
            var config1 = {
              method: 'post',
              url: `${server}user/profileDetails`,
              headers: {
                'Content-Type': 'application/json',
              },
              data: data1
            };

            axios(config1)
              .then(function (response1) {

                if (response1.data.status === true) {

                  setFollowings(response1.data.following);
                  setFollowers(response1.data.followers);

                  setQuestionsData(response1.data.questions);

                }

              })

              .catch(function (error) {
                console.log(error);
              });

          }

        })

        .catch(function (error) {
          console.log(error);
        });





    } else {
      setAuth(token);
    }

  }, [token])


  const [confirm, setConfirm] = useState('');
  const [password, setPassword] = useState('');
  const [oldPassword, setOldPassword] = useState('');
  const [userProfile, setUserProfile] = useState("")
  const [category, setCategory] = useState([])

  const [title, setTitle] = useState("")

  const [file, setFile] = useState([])

  const profileImage = useRef(null)

  const openChooseImage = () => {
    profileImage.current.click()
  }

  const changeProfileImage = event => {
    const ALLOWED_TYPES = ['image/png', 'image/jpeg', 'image/jpg']
    const selected = event.target.files[0]
    setFile(event.target.files[0]);


    if (selected && ALLOWED_TYPES.includes(selected.type)) {
      let reader = new FileReader()
      reader.onloadend = () => setUserProfile(reader.result)
      return reader.readAsDataURL(selected)
    }
    onOpen()
  }

  useEffect(() => {
    var data = JSON.stringify({});
    var config = {
      method: 'post',
      url: `${server}auth/viewAllCategory`,
      headers: {
        'Authorization': token,
        'Content-Type': 'application/json'
      },
      data: data

    };

    axios(config)
      .then(function (response) {

        if (response.data.status === true) {
          setCategoryList(response.data.data);

        }

      })

      .catch(function (error) {
        console.log(error);
      });
  }, [server, token])

  const AskQuestionsForm = useRef(null);

  const {
    register: register,
    formState: { errors: errors, isSubmitting: isSubmitting },
    handleSubmit: handleSubmit,
  } = useForm({
    mode: "onBlur",
  });

  const {
    register: register2,
    formState: { errors: errors2, isSubmitting: isSubmitting2 },
    handleSubmit: handleSubmit2,
  } = useForm({
    mode: "onBlur",
  });


  const {
    register: register3,
    formState: { errors: errors3, isSubmitting: isSubmitting3 },
    handleSubmit: handleSubmit3,
  } = useForm({
    mode: "onBlur",
  });


  const {
    register: register4,
    formState: { errors: errors4, isSubmitting: isSubmitting4 },
    handleSubmit: handleSubmit4,
  } = useForm({
    mode: "onBlur",
  });


  function onSubmit(values) {

    return new Promise((resolve) => {
      if (values.password === values.confirm) {

        var data = JSON.stringify({

          "password": values.password,
          "oldPassword": values.oldPassword
        });

        var config = {
          method: 'post',
          url: `${server}user/changePassword`,
          headers: {
            'Authorization': token,
            'Content-Type': 'application/json'
          },
          data: data
        };
        axios(config)
          .then(function (result) {

            if (result.data.status === true) {
              Change.onClose();
              resolve()
              notification.success({
                top: '10%',
                placement: 'topRight',
                message: result.data.message,
              });

            } else {
              resolve()
              notification.error({
                top: '10%',
                placement: 'topRight',
                message: result.data.message,
              });
            }
          })
          .catch(function (error) {
            console.log(error);
          });


      } else {
        notification.error({
          top: '10%',
          placement: 'topRight',
          message: "Password Mismatch",
        });
      }
    })
  }

  function editSubmit(values) {
    return new Promise((resolve) => {

      var FormData = require('form-data');
      var data = new FormData();
      data.append('firstName', values.firstName);
      data.append('lastName', values.lastName);
      data.append('companyName', values.companyName);
      data.append('mobile', values.mobile);
      data.append('aboutMe', values.aboutMe);
      data.append('image', file);

      var config = {
        headers: {
          'Authorization': token,
          'content-type': 'multipart/form-data'
        }
      };

      var url = `${server}user/editUser`;

      axios.post(url, data, config)
        .then(result => {
          if (result.data.status === true) {

            Edit.onClose();
            resolve()
            notification.success({
              top: '10%',
              placement: 'topRight',
              message: result.data.message,
            });

          } else {
            resolve()
            notification.error({
              top: '10%',
              placement: 'topRight',
              message: result.data.message,
            });
          }
        })

        .catch(function (error) {
          console.log(error);
        });
    })
  }

  function askSubmit(values) {
    return new Promise((resolve) => {

      var data = JSON.stringify({
        "title": values.title,
        "description": values.description,
        "categoryId": values.category
      });

      var config = {
        method: 'post',
        url: `${server}question/addQuestion`,
        headers: {
          'Authorization': token,
          'Content-Type': 'application/json'
        },
        data: data
      };
      axios(config)
        .then(function (result) {

          if (result.data.status === true) {
            Ask.onClose();
            history.push("/admin/dashboard");
            resolve()
            notification.success({
              top: '10%',
              placement: 'topRight',
              message: result.data.message,
              onClose: () => {

              },

            });
          } else {
            resolve()
            notification.error({
              top: '10%',
              placement: 'topRight',
              message: result.data.message,
            });
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    })
  }

  const [Followings, setFollowings] = useState([]);
  const [Followers, setFollowers] = useState([]);

  const [questionsData, setQuestionsData] = useState([]);

  function searchSubmit(values) {

    return new Promise((resolve) => {

      sessionStorage.setItem("search", search)
      window.dispatchEvent(new Event("storage"));

      resolve();
      window.location.hash !== "#/auth/search" ? history.push('search') : ""
    })
  }

  function signout() {

    sessionStorage.clear();

    history.push("/#/auth/home");
  }

  function myprofile() {

    history.push('myprofile');
  }

  const smVariant = { navigation: 'drawer', navigationButton: true }
  const mdVariant = { navigation: 'slider', navigationButton: false }
  const [isSidebarOpen, setSidebarOpen] = useState(false)
  const variants = useBreakpointValue({ base: smVariant, md: mdVariant })
  const toggleSidebar = () => setSidebarOpen(!isSidebarOpen)

  return (
    <Flex variant={variants?.navigation}
      isOpen={isSidebarOpen} onClose={toggleSidebar}
      // pe={{ sm: "0px", md: "16px" }}
      // w={{ sm: "100%", md: "auto" }}
      alignItems='center'
      flexDirection='row' gap="10px" >
      <Menu>
        <Flex flexDirection="column">
          <Button fontFamily={theme.fonts.button} fontSize="sm" onClick={() => { history.push("/#/auth/home") }} > <Icon as={FaHome} color='white.400' fontWeight="700" fontSize='md' me='6px'></Icon>Home
          </Button>
        </Flex>
        <Flex flexDirection="row">
          <form onSubmit={handleSubmit4(searchSubmit)} >
            <FormControl isInvalid={errors4.title}  >
              <Flex flexDirection="row">
                <InputGroup >
                  <InputLeftElement
                    pointerEvents='none'
                    children={<SearchIcon color='gray.600' />}
                  />
                  <Input
                    id='search'
                    _placeholder={{ color: "gray.600" }}
                    placeholder='Enter  Search'
                    {...register4('search', {
                      required: 'This is required',
                    })}
                    name="search"
                    w="md"
                    // style={{ width: '500px' }}
                    fontFamily={theme.fonts.button}
                    defaultValue={sessionStorage.getItem("search")}
                    onChange={event => setSearch(event.currentTarget.value)}
                  />
                  <FormErrorMessage>
                    {errors4.search && errors4.search.message}
                  </FormErrorMessage>

                  <InputRightElement

                    children={<CloseIcon onClick={(e) => { sessionStorage.removeItem("search"), window.location.reload() }} style={{ cursor: 'pointer' }} font-size='12px' color='gray.600' />} />

                </InputGroup>

                <Button fontFamily={theme.fonts.button} fontSize="sm" me='6px' isLoading={isSubmitting4} type='submit'>
                  Search
                </Button>
              </Flex>

            </FormControl>
          </form>
        </Flex>

        {token !== null ? <>

          <Button fontFamily={theme.fonts.button} fontSize="sm" onClick={() => { Ask.onOpen(); }} me='6px'   > Ask Questions</Button>

        </> : ""}

        <Flex flexDirection="column">

          {/*  <Button
               onClick={toggleColorMode}
               color={colorMode === "light" ? "Dark" : "Light"}
               me='6px'
             >
               <FaMoon  /> 
             </Button> */}

        </Flex>

        {token !== null ? <>
          <Flex flexDirection="column">
            <Menu >
              <MenuButton
                as={IconButton}
                aria-label='Options'
                icon={<FaUserAlt />}
                me='6px' />
              <MenuList>

                {auth.role === "ADMIN" ? <>
                  <MenuItem onClick={() => { history.push("/admin/dashboard"); }} >

                    <Flex alignItems="center"  >  <AiFillFire style={{ marginRight: '5px' }} /> Dashboard </Flex>

                  </MenuItem></> : ""}

                <MenuItem onClick={(e) => { myprofile() }}>
                  <Flex alignItems="center">  <FaUserCircle style={{ marginRight: '5px' }} />   My Profile   </Flex>

                </MenuItem>

                <MenuItem onClick={(e) => { Edit.onOpen() }} >
                  <Flex alignItems="center"  >  <FaUserEdit style={{ marginRight: '5px' }} />   Update Profile   </Flex>

                </MenuItem>

                <MenuItem onClick={Change.onOpen}  >

                  <Flex alignItems="center" >  <MdPassword style={{ marginRight: '5px' }} />  Change Password  </Flex>

                </MenuItem>

                <MenuItem onClick={signout}  >

                  <Flex alignItems="center" >  <FaSignOutAlt style={{ marginRight: '5px' }} />Sign Out </Flex>

                </MenuItem>

              </MenuList>
            </Menu>
          </Flex> </> :
          <>

            <Button onClick={() => { history.push('signin'); }} fontFamily={theme.fonts.button} fontSize="sm" > Login</Button>
          </>
        }

      </Menu>

      <Modal isOpen={Ask.isOpen} onClose={Ask.onClose} size="4xl"  >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader fontFamily={theme.fonts.userDetails} fontWeight="light">Ask Question</ModalHeader>
          <ModalCloseButton />
          <form onSubmit={handleSubmit3(askSubmit)}>

            <ModalBody pb={6}>

              <FormControl isInvalid={errors3.title}>
                <FormLabel htmlFor='title' fontFamily={theme.fonts.userAbout}>Title</FormLabel>
                <Input
                  id='title'
                  placeholder='Enter Question'
                  {...register3('title', {
                    required: 'Title is required',

                  })}
                  fontFamily={theme.fonts.button}
                  onChange={event => setTitle(event.currentTarget.value)}
                />
                <FormErrorMessage>
                  {errors3.title && errors3.title.message}
                </FormErrorMessage>
              </FormControl>

              <FormControl isInvalid={errors3.description}>
                <FormLabel htmlFor='description' fontFamily={theme.fonts.userAbout}>Description</FormLabel>
                <Textarea
                  id='description'
                  placeholder='Enter Description'
                  {...register3('description', {
                    required: 'Description is required',

                  })}
                  fontFamily={theme.fonts.button} border="none"

                  onChange={event => setDescription(event.currentTarget.value)}
                />
                <FormErrorMessage>
                  {errors3.description && errors3.description.message}
                </FormErrorMessage>
              </FormControl>

              <FormControl isInvalid={errors3.category}>
                <FormLabel htmlFor='category' fontFamily={theme.fonts.userAbout}>Category</FormLabel>

                <Select
                  labelId="demo-simple-select-label"
                  id='category'
                  defaultValue={category}
                  label="Category"
                  onChange={e => setCategory(e.currentTarget.value)}
                  name='category'
                  {...register3('category', {
                    required: 'Category is required',

                  })}
                  fontFamily={theme.fonts.button} fontSize="sm" fontWeight="light"
                >
                  <option value=""> Select Category</option>

                  {categoryList.map((row, index, arr) => {
                    return (
                      <option value={row.id}>{row.title}</option>

                    );
                  })}

                </Select>

                <FormErrorMessage>
                  {errors3.category && errors3.category.message}
                </FormErrorMessage>
              </FormControl>

            </ModalBody>

            <ModalFooter>
              <Button mt={4} colorScheme='teal' isLoading={isSubmitting3} type='submit'>
                Submit
              </Button>
            </ModalFooter>

          </form>
        </ModalContent>
      </Modal>


      <Modal isOpen={Edit.isOpen} onClose={Edit.onClose} size="4xl"  >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader fontFamily={theme.fonts.userDetails}>Edit Profile</ModalHeader>
          <ModalCloseButton />

          {token !== null ? <>
            <form onSubmit={handleSubmit2(editSubmit)}>
              <ModalBody pb={6}>


                <VStack spacing={3} py={5} borderBottomWidth={1} borderColor="brand.light">
                  <Avatar
                    size="2xl"
                    name={auth.firstName}
                    cursor="pointer"
                    onClick={openChooseImage}
                    src={userProfile ? userProfile : 'http://ec2-34-201-174-68.compute-1.amazonaws.com:3000/' + auth.image}
                  >
                    <AvatarBadge bg='blue.500' boxSize="1em">
                      <Icon as={VscDeviceCamera} style={{ cursor: 'pointer' }} color='white' fontWeight="700" fontSize='md' ></Icon>
                    </AvatarBadge>
                  </Avatar>
                  <input
                    hidden
                    type="file"
                    ref={profileImage}
                    onChange={changeProfileImage}
                  />
                  <Modal isOpen={isOpen} onClose={onClose}>
                    <ModalOverlay />
                    <ModalContent>
                      <ModalHeader >Something went wrong</ModalHeader>
                      <ModalCloseButton />
                      <ModalBody>
                        <Text>File not supported!</Text>
                        <HStack mt={1}>
                          <Text color="brand.cadet" fontSize="sm">
                            Supported types:
                          </Text>
                          <Badge colorScheme="green">PNG</Badge>
                          <Badge colorScheme="green">JPG</Badge>
                          <Badge colorScheme="green">JPEG</Badge>
                        </HStack>
                      </ModalBody>

                      <ModalFooter>
                        <Button onClick={onClose}>Close</Button>
                      </ModalFooter>
                    </ModalContent>
                  </Modal>
                  <VStack spacing={1}>
                    <Heading as="h3" fontSize="xl" color="brand.dark" fontFamily={theme.fonts.userName}> {auth.firstName}   </Heading>
                    <Text color="brand.gray" fontSize="sm">  </Text>
                  </VStack>
                </VStack>

                <FormControl isInvalid={errors2.aboutMe} mt={4}>
                  <FormLabel fontFamily={theme.fonts.userAbout} fontWeight='bold' htmlFor='oldPassword'>About Me</FormLabel>
                  <Textarea
                    id='aboutMe'
                    placeholder='Enter About Me'
                    {...register2('aboutMe')}
                    defaultValue={auth.aboutMe}
                    fontFamily={theme.fonts.body} fontWeight='thin'
                  />
                  <FormErrorMessage>
                    {errors2.aboutMe && errors2.aboutMe.message}
                  </FormErrorMessage>
                </FormControl>

                <Flex align="left" gap={2}>
                  <FormControl isInvalid={errors2.firstName} mt={4}>
                    <FormLabel fontFamily={theme.fonts.userAbout} fontWeight='bold' htmlFor='firstName'>First Name</FormLabel>
                    <Input
                      id='firstName'
                      placeholder='Enter Your First Name'
                      {...register2('firstName', {
                        required: 'FirstName is required',

                      })}
                      defaultValue={auth.firstName}
                      fontFamily={theme.fonts.body} fontWeight='thin'
                    />
                    <FormErrorMessage>
                      {errors2.firstName && errors2.firstName.message}
                    </FormErrorMessage>
                  </FormControl>

                  <FormControl isInvalid={errors2.lastName} mt={4}>
                    <FormLabel fontFamily={theme.fonts.userAbout} fontWeight='bold' htmlFor='lastName'> Last Name</FormLabel>
                    <Input
                      id='lastName'
                      placeholder='Enter Your Last Name'
                      {...register2('lastName', {
                        required: 'Last Name is required',

                      })}
                      fontFamily={theme.fonts.body} fontWeight='thin'
                      defaultValue={auth.lastName}
                    />
                    <FormErrorMessage>
                      {errors2.confirm && errors2.confirm.message}
                    </FormErrorMessage>
                  </FormControl>

                </Flex>



                <Flex align="left" gap={2}>
                  <FormControl isInvalid={errors2.companyName} mt={4}>
                    <FormLabel fontFamily={theme.fonts.userAbout} fontWeight='bold' htmlFor='companyName'>Company Name</FormLabel>
                    <Input
                      id='companyName'
                      placeholder='Enter Your Company Name'
                      {...register2('companyName', {
                        required: 'Company Name is required',

                      })}
                      fontFamily={theme.fonts.body} fontWeight='thin'
                      defaultValue={auth.companyName}

                    />
                    <FormErrorMessage>
                      {errors2.companyName && errors2.companyName.message}
                    </FormErrorMessage>
                  </FormControl>

                  <FormControl isInvalid={errors2.mobile} mt={4}>
                    <FormLabel fontFamily={theme.fonts.userAbout} fontWeight='bold' htmlFor='mobile'>Mobile</FormLabel>
                    <Input
                      type="number"
                      id='mobile'
                      placeholder='Enter Your Mobile'
                      {...register2('mobile')}
                      defaultValue={auth.mobile}
                      fontFamily={theme.fonts.body} fontWeight='thin'
                    />
                    <FormErrorMessage>
                      {errors2.confirm && errors2.confirm.message}
                    </FormErrorMessage>
                  </FormControl>

                </Flex>

              </ModalBody>

              <ModalFooter>
                <Flex align="left" gap={2}>

                  <Button mt={4}
                    fontSize='15px'
                    variant='dark'
                    fontWeight='bold'
                    w='100%'
                    h='45'
                    mb='24px'
                    isLoading={isSubmitting2} type='submit'
                  >
                    Save
                  </Button>


                </Flex></ModalFooter>
            </form>
          </> : ""}
        </ModalContent>
      </Modal>


      <Modal isOpen={Change.isOpen} onClose={Change.onClose} size="2xl"  >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader fontFamily={theme.fonts.userDetails}>Change Password</ModalHeader>
          <ModalCloseButton />
          {token !== null ? <>
            <form onSubmit={handleSubmit(onSubmit)}>
              <ModalBody pb={6}>



                <FormControl isInvalid={errors.password} mt={4}>
                  <FormLabel fontFamily={theme.fonts.userAbout} htmlFor='oldPassword'>Old Password</FormLabel>
                  <Input
                    id='oldPassword'
                    placeholder='Enter Your Old Password'
                    {...register('oldPassword', {
                      required: 'Old Password is required',
                    })}
                    fontFamily={theme.fonts.body}

                    onChange={event => setOldPassword(event.currentTarget.value)}
                  />
                  <FormErrorMessage>
                    {errors.password && errors.password.message}
                  </FormErrorMessage>
                </FormControl>

                <Flex align="left" gap={2}>
                  <FormControl isInvalid={errors.password} mt={4}>
                    <FormLabel fontFamily={theme.fonts.userAbout} htmlFor='password'>Password</FormLabel>
                    <Input
                      id='password'
                      placeholder='Enter Your Password'
                      {...register('password', {
                        required: 'Password is required',

                      })}
                      fontFamily={theme.fonts.body}
                      onChange={event => setPassword(event.currentTarget.value)}
                    />
                    <FormErrorMessage>
                      {errors.password && errors.password.message}
                    </FormErrorMessage>
                  </FormControl>

                  <FormControl isInvalid={errors.confirm} mt={4}>
                    <FormLabel fontFamily={theme.fonts.userAbout} htmlFor='confirm'> Confirm Password</FormLabel>
                    <Input
                      id='confirm'
                      placeholder='Enter Your  Confirm Password'
                      {...register('confirm', {
                        required: 'Confirm Password is required',

                      })}
                      fontFamily={theme.fonts.body}
                      onChange={event => setConfirm(event.currentTarget.value)}
                    />
                    <FormErrorMessage>
                      {errors.confirm && errors.confirm.message}
                    </FormErrorMessage>
                  </FormControl>

                </Flex>

              </ModalBody>

              <ModalFooter>

                <Flex align="left" gap={2}>

                  <Button mt={4}
                    fontSize='15px'
                    variant='dark'
                    fontWeight='bold'
                    w='100%'
                    h='45'
                    mb='24px'
                    isLoading={isSubmitting} type='submit'
                  >
                    Save
                  </Button>

                </Flex>
              </ModalFooter>
            </form>
          </> : ""}
        </ModalContent>
      </Modal>




    </Flex>
  );
}