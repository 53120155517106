// Chakra imports
import React, { useEffect, useRef, useState, useReducer } from 'react';

import {

  Box,
  Button,
  Flex,
  Grid,
  GridItem,
  Icon,
  Image,
  Link,
  Switch,
  Text,
  useColorMode,
  useColorModeValue,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Input,
  Textarea,
  FormLabel,
  chakra,
  Container,
  Stack,
  Show,
  color,
  SimpleGrid,
  FormControl,
  Divider,
  VStack,
  HStack,
  Heading,
  Badge,
  Avatar, AvatarBadge,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList
} from "@chakra-ui/react";
// Assets
import { BsThreeDotsVertical } from "react-icons/bs"
import { AiFillLike } from "react-icons/ai"
import { BiLike, BiChat, BiShare } from "react-icons/bi";

// Custom components
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import CardFooter from "components/Card/CardFooter";

import {
  FaCube,
  FaPenFancy,
  FaPlus,
  FaArrowCircleLeft,
  FaUserCircle,
  FaInstagram,
  FaTwitter,
  FaYoutube,
  FaFacebook,
  FaUserPlus,
  FaUserCheck,
  FaEdit,
  FaCheck
} from "react-icons/fa";
import { Tabs, TabList, TabPanels, Tab, TabPanel } from '@chakra-ui/react'

import axios from 'axios';
import { useHistory } from 'react-router-dom';
import moment from "moment";

import jwt_decode from "jwt-decode";
import back from "../../assets/img/back1.jpg"
import back1 from "./2.png"
import theme from 'theme/theme';

function Myprofile() {
  const { colorMode } = useColorMode();
  const [show, setShow] = useState(false);



  // Chakra color mode
  const textColor = useColorModeValue("gray.700", "white");
  const textColorAbout = useColorModeValue("blue.600", "white");
  const iconColor = useColorModeValue("blue.500", "white");
  const bgProfile = useColorModeValue("hsla(0,0%,100%,.8)", "navy.800");
  const borderProfileColor = useColorModeValue("white", "transparent");
  const emailColor = useColorModeValue("gray.400", "gray.300");


  const [profileData, setProfileData] = useState([]);
  const [Followings, setFollowings] = useState([]);
  const [Followers, setFollowers] = useState([]);
  const [Followerslist, setFollowerslist] = useState([]);
  const [Followinglist, setFollowinglist] = useState([]);

  const [Followhide, setFollowhide] = useState([]);
  const [questionsData, setQuestionsData] = useState([]);

  const server= "https://23.20.212.243:8080/api/";
  // const server= "http://ec2-34-201-174-68.compute-1.amazonaws.com:3000/api/";

  const token = sessionStorage.getItem("token");

  const [auth, setAuth] = useState();


  const [reducerValue, forceUpdate] = useReducer(x => x + 1, 0);


  const [display, setDisplay] = useState(false);

  const history = useHistory();

  const FollowerList = useDisclosure()
  const FollowingList = useDisclosure()


  const { isOpen, onOpen, onClose } = useDisclosure()



  useEffect(() => {


    if (token !== null) {
      setShow(true);
      var decode = jwt_decode(token);

      setAuth(decode.user_id);


    } else {
      setShow(false);
    }


  }, [])





  useEffect(() => {

    const fetchData = async () => {


      setDisplay(false);



      var data = JSON.stringify({
        id: auth
      });
      var config = {
        method: 'post',
        url: `${server}user/userDetails`,
        headers: {
          'Authorization': token,
          'Content-Type': 'application/json',
        },
        data: data
      };

      axios(config)
        .then(function (response) {


          if (response.data.status === true) {
            setDisplay(true);

            setProfileData(response.data.user_data);
            setFollowings(response.data.following);
            setFollowers(response.data.followers);
            setQuestionsData(response.data.questions);


          }

        })



      var data1 = JSON.stringify({
        id: auth
      });
      var config1 = {
        method: 'post',
        url: `${server}user/isFollow`,
        headers: {
          'Authorization': token,
          'Content-Type': 'application/json'
        },
        data: data1

      };

      axios(config1)
        .then(function (response1) {

          if (response1.data.status === true) {
            setFollowhide(response1.data.data);
          } else {
            setFollowhide("none");
          }

        })

        .catch(function (error) {
          console.log(error);
        });

    }
    fetchData()
  }, [reducerValue, server, auth])


  useEffect(() => {

    const fetchData = async () => {


      var data = JSON.stringify({

      });
      var config = {
        method: 'post',
        url: `${server}follow/followinglist`,
        headers: {
          'Authorization': token,
          'Content-Type': 'application/json',
        },
        data: data
      };

      axios(config)
        .then(function (response) {


          if (response.data.status === true) {



            setFollowinglist(response.data.data);


          }

        })



      var data = JSON.stringify({

      });
      var config = {
        method: 'post',
        url: `${server}follow/followerslist`,
        headers: {
          'Authorization': token,
          'Content-Type': 'application/json',
        },
        data: data
      };

      axios(config)
        .then(function (response) {

          if (response.data.status === true) {


            setFollowerslist(response.data.data);



          }

        })

    }
    fetchData()

  }, [reducerValue, server, auth])





  /** follow */


  function follow(id) {
    var data = JSON.stringify({
      "id": id
    });

    var config = {
      method: 'post',
      url: `${server}follow/followUser`,
      headers: {
        'Authorization': token,
        'Content-Type': 'application/json'
      },
      data: data
    };

    axios(config)
      .then(function (result) {



        forceUpdate();

      });

  }
  /** unFollow */

  function unFollow(id) {

    var data = JSON.stringify({
      "id": id
    });

    var config = {
      method: 'post',
      url: `${server}follow/unfollowUser`,
      headers: {
        'Authorization': token,
        'Content-Type': 'application/json'
      },
      data: data
    };

    axios(config)
      .then(function (result) {

        forceUpdate();

      });

  }


  return (
    <>


      {colorMode === "light" ?
        <>
          <Image src={back} alt='Dan Abramov' width='100%' position="fixed" top="0" zIndex="-1" />

        </>
        : <Image src={back1} alt='Dan Abramov' width='100%' height='120vh' position="fixed" top="0" zIndex="-1" />
      }

      <Flex gap='2' direction='row' pt={{ base: "120px", md: "75px" }} h='100%' style={{ justifyContent: 'center' }} className="myprofile">

        {display === true ?
          <>
            <Card maxW='7xl' >

              <Button style={{ width: '8%' }} onClick={() => { history.push("/#/auth/home") }} >
                <Icon as={FaArrowCircleLeft} color='white.400' fontWeight="700" fontSize='md' me='6px'></Icon>Back
              </Button>

              <Flex gap='2' pt={{ base: "20px" }}  >

                <Avatar
                  size="2xl"

                  cursor="pointer"

                  src={'http://ec2-34-201-174-68.compute-1.amazonaws.com:3000/' + profileData.image}
                >
                </Avatar>

                <Flex p="2" direction='column'    >

                  <Text as='b' fontFamily={theme.fonts.userName} color="cyan.800">    {profileData.firstName} {profileData.lastName}    </Text>

                  <Text >    {profileData.aboutMe}     </Text>


                  <Flex gap='4'>
                    <Flex gap='2' alignItems="center" onClick={(e) => { FollowerList.onOpen() }}>


                      <Text alignItems="end" fontSize='lg' color={textColor} >  {Followers}   </Text>
                      <Icon color={textColor} as={FaUserPlus} />

                      <Text alignItems="center" fontSize='lg' color={textColor} style={{ cursor: 'pointer' }}> Followers  </Text>
                    </Flex>

                    <Flex gap='2' alignItems="center" onClick={(e) => { FollowingList.onOpen() }} >

                      <Text alignItems="center" fontSize='lg' color={textColor} > {Followings} </Text>
                      <Icon color={textColor} as={FaUserCheck} />
                      <Text alignItems="center" fontSize='lg' color={textColor} style={{ cursor: 'pointer' }}>      Followings</Text>

                    </Flex>

                  </Flex>
                </Flex>



              </Flex>



              <Divider orientation='horizontal' border='0' borderColor='rgb(192 188 188)' borderStyle='solid' borderBottomWidth='1px' width='100%' marginTop='20px' />


              <Tabs variant='enclosed' pt={{ base: "20px" }}>
                <TabList style={{ width: '100%' }}>
                  <Tab fontFamily={theme.fonts.userAbout}>  Profile Information</Tab>


                  <Tab fontFamily={theme.fonts.userAbout}>  Knowledge Sharing </Tab>


                </TabList>

                <TabPanels>
                  <TabPanel>
                    <Card style={{ boxShadow: 'none' }}   >
                      <CardHeader p='12px 12px' mb='6px'>  <Text fontSize='lg' textAlign="center" borderRadius="10px" bg={"gray.600"} color={'orange.50'} fontWeight='bold' fontFamily={theme.fonts.userAbout}>   About Me  </Text>    </CardHeader>
                      <CardBody px='3px'>



                        <Flex direction='column'>
                          <Text fontSize='md' color={textColor} fontWeight='400' mb='30px'>
                            {profileData.aboutMe}
                          </Text>
                          <Flex align='center' mb='18px'>
                            <Text
                              fontSize='md'
                              color={textColor}
                              fontWeight='bold'
                              me='10px' fontFamily={theme.fonts.userAbout}  w="150px">
                              Full Name: { }
                            </Text>
                            <Text fontSize='md' color={textColorAbout} fontWeight='400' fontFamily={theme.fonts.userName}>
                              {profileData.firstName} {profileData.lastName}
                            </Text>
                          </Flex>

                          <Flex align='center' mb='18px'>
                            <Text
                              fontSize='md'
                              color={textColor}
                              fontWeight='bold'
                              me='10px' fontFamily={theme.fonts.userAbout}  w="150px">
                              Email:{" "}
                            </Text>
                            <Text fontSize='md' color={textColorAbout} fontWeight='400' fontFamily={theme.fonts.userName}>
                              {profileData.email}
                            </Text>
                          </Flex>
                          <Flex align='center' mb='18px'>
                            <Text
                              fontSize='md'
                              color={textColor}
                              fontWeight='bold'
                              me='10px' fontFamily={theme.fonts.userAbout}  w="150px">
                              Company Name:{" "}
                            </Text>
                            <Text fontSize='md' color={textColorAbout} fontWeight='400' fontFamily={theme.fonts.userName}>
                              {profileData.companyName}
                            </Text>
                          </Flex>

                          <Flex align='center' mb='18px'>
                            <Text
                              fontSize='md'
                              color={textColor}
                              fontWeight='bold'
                              me='10px' fontFamily={theme.fonts.userAbout}  w="150px">
                              Joined Date :{" "}
                            </Text>
                            <Text fontSize='md' color={textColorAbout} fontWeight='400' fontFamily={theme.fonts.userAbout}>

                              {moment(profileData.createdAt).format("DD-MM-YYYY hh:mm")}

                            </Text>
                          </Flex>

                        </Flex>

                      </CardBody>
                    </Card>
                  </TabPanel>

                  <TabPanel>

                    <Card style={{ boxShadow: 'none' }}  >
                      <CardHeader >   </CardHeader>
                      <CardBody px='3px'>
                        <Flex direction='column'>

                          {questionsData.map((row, index, arr) => {
                            return (

                              <>

                                <Card  >
                                  <CardHeader>
                                    <Flex spacing='2' gap="10px">
                                      <Flex flex='1' gap='2' alignItems='center' flexWrap='wrap'>
                                        <Icon as={FaUserCircle} color='white.400' fontSize='50px' me='6px' ></Icon>

                                        <Box >
                                          <Heading size='sm' mt='2px' fontFamily={theme.fonts.userName}><Link textAlign='center' py='3px' px='3px' color="blue.600" href={'#/auth/profile'} onClick={(e) => { sessionStorage.setItem("userId", row.userId); }}>{row.user ? row.user.firstName : ""} {row.userId === auth ? "( You )" : ""}</Link></Heading>
                                          <Flex mt="5px" gap="7px"> <Text fontSize="15px" fontFamily={theme.fonts.category} fontWeight='200' bg="gray.500" borderRadius="20px" px="10px" py='4px' color="gray.50" _hover={{ bg: "gray.200", color: "gray.600" }}  >{row.category.title} </Text>  <Text mt="3px"> {moment(row.createdAt).format("DD-MM-YYYY hh:mm")}</Text> </Flex>
                                        </Box>
                                      </Flex>

                                      {row.userId === auth ?
                                        <>

                                          <Menu>
                                            <MenuButton
                                              as={IconButton}
                                              aria-label='Options'
                                              icon={<BsThreeDotsVertical />}
                                              variant='outline'

                                            />

                                            <MenuList>

                                              <MenuItem onClick={() => {
                                                Edit.onOpen(); setEdit(
                                                  {
                                                    "id": row.id,
                                                    "title": row.title,
                                                    "description": row.description,
                                                    "category": row.categoryId,
                                                  }
                                                );
                                              }}>

                                                <Flex alignItems="center"  >  <Icon as={FaEdit} color='white.400' fontSize='15px' me='6px' ></Icon>  Edit </Flex>

                                              </MenuItem>

                                            </MenuList>
                                          </Menu>
                                        </> : ""}
                                    </Flex>

                                  </CardHeader>
                                  <CardBody mt='4'>
                                    <Heading className='question' fontSize='lg' fontFamily={theme.fonts.title} fontWeight='600' ><Link color="red.600" href={'#/auth/article'} onClick={(e) => { sessionStorage.setItem("articleId", row.id); }} >{row.title}</Link></Heading>

                                    <Flex  >
                                      <Text pt='2' fontFamily={theme.fonts.message} fontSize="md">

                                        {row.description.length > 400 ? <>
                                          {row.description.substring(0, 500) + "..."}
                                          <Link color="blue.400" href={'#/auth/article'} onClick={(e) => { sessionStorage.setItem("articleId", row.id); }} >Read More</Link>
                                        </>
                                          : row.description}
                                      </Text>
                                    </Flex>
                                  </CardBody>

                                  <CardFooter pt='2'
                                    justify='left'
                                    gap='2'
                                    flexWrap='wrap'
                                    sx={{
                                      '& > button': {
                                        minW: '136px',
                                      },
                                    }}
                                  >

                                    <Flex alignItems='center' gap="2" >


                                      <Flex alignItems='center' gap='2' > <Text fontFamily={theme.fonts.message} fontSize="sm" fontWeight={"bold"}> Total Likes:</Text> <Text as="b">   {row.likes_count} </Text> </Flex>

                                      <Flex alignItems='center' gap='2'>   |  <Text fontFamily={theme.fonts.message} fontSize="sm" fontWeight={"bold"}> Total Comments: </Text>   <Text as="b">{row.comments ? row.comments.length : 0}</Text>  </Flex>
                                    </Flex>

                                  </CardFooter>
                                </Card>


                              </>
                            );
                          })}

                        </Flex>

                      </CardBody>
                    </Card>

                  </TabPanel>

                </TabPanels>
              </Tabs>

            </Card>

          </>
          :

          <Card maxW='7xl' >
            <div className="loader"></div>
          </Card>
        }
      </Flex>

      <Modal size="3xl" isOpen={FollowerList.isOpen} onClose={FollowerList.onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader fontFamily={theme.fonts.userDetails}>Follower List</ModalHeader>
          <ModalCloseButton />
          <ModalBody >

            {Followerslist.map((row, index, arr) => {

              return (
                <>

                  <Flex gap="2">
                    <Icon as={FaCheck} color='white.400'></Icon>
                    <Text fontSize="md" color={textColor} fontWeight="normal"  >     {row.user ? row.user.firstName : ""}   {row.user ? row.user.lastName : ""}  </Text>
                    <Text fontSize="md" color={textColor} fontWeight="normal"  >    </Text>
                  </Flex>

                </>);
            })}


          </ModalBody>
        </ModalContent>

      </Modal>


      <Modal size="3xl" isOpen={FollowingList.isOpen} onClose={FollowingList.onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader fontFamily={theme.fonts.userDetails}>FollowingList</ModalHeader>
          <ModalCloseButton />

          <ModalBody >


            {Followinglist.map((row, index, arr) => {


              return (
                <>


                  <Flex gap="2">
                    <Icon as={FaCheck} color='green.400' fontSize="15px" mt='5px'></Icon>
                    <Text fontSize="md" color={textColorAbout} fontWeight="bold" fontFamily={theme.fonts.userName}  >     {row.following.firstName}   {row.following.lastName}  </Text>
                    <Text fontSize="md" color={textColor} fontWeight="normal" fontFamily={theme.fonts.userName}  >    {show === true ? Followhide.includes(row.followingId) === true ?

                      <Button fontFamily={theme.fonts.button} h='1.55rem' py='3px' size='sm' fontWeight='bold' onClick={() => { unFollow(row.followingId) }}> Unfollow </Button>
                      :

                      <Button fontFamily={theme.fonts.button} h='1.55rem' py='3px' size='sm' fontWeight='bold' onClick={() => { follow(row.followingId) }}> Follow</Button>

                      : ""} </Text>
                  </Flex>

                </>);
            })}

          </ModalBody>
        </ModalContent>

      </Modal>

    </>
  );
}

export default Myprofile;
