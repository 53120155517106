// Chakra Icons
import { BellIcon, HamburgerIcon } from "@chakra-ui/icons";
import { useState, useEffect } from "react";

// Chakra Imports
import {
  Box,
  Button,
  Flex,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Stack,
  Text,
  useColorMode,
  useColorModeValue,
  Switch,
  Link,
  IconButton,
  Icon
} from "@chakra-ui/react";
// Assets
import {
  FaMoon
} from "react-icons/fa";
// Custom Icons
import { ArgonLogoDark, ArgonLogoLight, ChakraLogoDark, ChakraLogoLight, ProfileIcon, SettingsIcon } from "components/Icons/Icons";
// Custom Components
import { ItemContent } from "components/Menu/ItemContent";
import { SearchBar } from "components/Navbars/SearchBar/SearchBar";
import { SidebarResponsive } from "components/Sidebar/Sidebar";
import React from "react";
import { NavLink } from "react-router-dom";
import routes from "routes.js";
import { GoSettings } from "react-icons/go";
import { AiFillHome } from "react-icons/ai";
import { FaSignOutAlt } from "react-icons/fa";
import { useHistory } from 'react-router-dom';

import jwt_decode from "jwt-decode";
import { RoundedCorner } from "@material-ui/icons";


export default function HeaderLinks(props) {
  const {
    variant,
    children,
    fixed,
    scrolled,
    secondary,
    onOpen,
    sidebarVariant,
    setSidebarVariant,
    isOpen,
    onClose,
    ...rest
  } = props;

  const { colorMode, toggleColorMode } = useColorMode();
  const [switched, setSwitched] = useState(props.isChecked);


  let bgButton = useColorModeValue(
    "linear-gradient(81.62deg, #313860 2.25%, #151928 79.87%)",
    "white"
  );
  let colorButton = useColorModeValue("white", "gray.700");
  const secondaryButtonBg = useColorModeValue("white", "transparent");
  const secondaryButtonBorder = useColorModeValue("gray.700", "white");
  const secondaryButtonColor = useColorModeValue("gray.700", "white");
  const bgDrawer = useColorModeValue("white", "navy.800");
  const settingsRef = React.useRef();

  // Chakra Color Mode
  let navbarIcon =
    fixed && scrolled
      ? useColorModeValue("gray.700", "gray.200")
      : useColorModeValue("white", "gray.200");
  let menuBg = useColorModeValue("white", "navy.800");
  if (secondary) {
    navbarIcon = "white";
  }

  const history = useHistory();

  const [auth, setAuth] = useState([]);

  const token = sessionStorage.getItem("token");

  useEffect(() => {


    if (token !== null) {

      var decode = jwt_decode(token);

      setAuth(decode.user_id);

    } else {
      setAuth(token);
    }

  }, [token])

  function signout() {

    sessionStorage.clear();
    window.location.reload()
  }

  return (
    <Flex
      pe={{ sm: "0px", md: "16px" }}
      w={{ sm: "100%", md: "auto" }}
      alignItems='center'
      flexDirection='row'>

      <SidebarResponsive
        logo={
          <Stack direction='row' spacing='12px' align='center' justify='center'>
            {colorMode === "dark" ? (
              <ArgonLogoLight w='74px' h='27px' />
            ) : (
              <ArgonLogoDark w='74px' h='27px' />
            )}
            <Box
              w='1px'
              h='20px'
              bg={colorMode === "dark" ? "white" : "gray.700"}
            />
            {colorMode === "dark" ? (
              <ChakraLogoLight w='82px' h='21px' />
            ) : (
              <ChakraLogoDark w='82px' h='21px' />
            )}
          </Stack>
        }
        colorMode={colorMode}
        secondary={props.secondary}
        routes={routes}
        {...rest}
      />

      {/**   <Button   onClick={toggleColorMode}   color={colorMode === "light" ? "Dark" : "Light"} me='6px'  > 
               <FaMoon  />  </Button> */}


      {token !== null ? <>

        <Menu>
          <MenuButton
            as={IconButton}
            aria-label='Options'
            icon={<GoSettings />}
            bg="gray.600"
            color="gray.50"
            me='6px'
            borderRadius="100px"
            _hover={{color:"gray.600", bg:"gray.50"}}
          />
          <MenuList>
            <MenuItem onClick={() => { history.push("/auth/home"); }} >

              <Flex alignItems="center" >     <Icon as={AiFillHome} color='white.400' me='6px' ></Icon>    Home </Flex>

            </MenuItem>



            <MenuItem onClick={signout}  >

              <Flex alignItems="center" >  <FaSignOutAlt style={{ marginRight: '5px' }} />Sign Out </Flex>

            </MenuItem>

          </MenuList>
        </Menu>
      </> : ""}





    </Flex>
  );
}