// Chakra imports
import {
  Box,
  Button,
  Flex,
  Grid,
  Progress,
  SimpleGrid,
  Stat,
  StatLabel,
  StatNumber,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorMode,
  useColorModeValue,
} from "@chakra-ui/react";
// Custom components
import Card from "components/Card/Card.js";
import IconBox from "components/Icons/IconBox";
// Custom icons
import {
  CartIcon,
  DocumentIcon,
  GlobeIcon,
  WalletIcon,
  ProfileIcon,
} from "components/Icons/Icons.js";

import React, { useState, useEffect } from "react";

import axios from "axios";
import { FaQuestion } from "react-icons/fa";
import theme from "theme/theme";

export default function Dashboard() {
  // Chakra Color Mode
  const iconBlue = useColorModeValue("blue.500", "blue.500");
  const iconBoxInside = useColorModeValue("white", "white");
  const textColor = useColorModeValue("gray.700", "white");
  const tableRowColor = useColorModeValue("#F7FAFC", "navy.900");
  const borderColor = useColorModeValue("gray.200", "gray.600");
  const textTableColor = useColorModeValue("gray.500", "white");

  const { colorMode } = useColorMode();

  const [userCount, setUserCount] = useState();
  const [questionCount, setQuestionCount] = useState();
  const server= sessionStorage.getItem("server");

  const token = sessionStorage.getItem("token");

  useEffect(() => {
    var data = JSON.stringify({});
    var config = {
      method: "post",
      url: `${server}dashboard/adminDashboard`,
      headers: {
        Authorization: token,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        if (response.data.status === true) {
          setUserCount(response.data.user_count);
          setQuestionCount(response.data.question_count);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  return (
    <Flex flexDirection='column' pt={{ base: "120px", md: "75px" }}>
      <SimpleGrid columns={{ sm: 1, md: 2, xl: 4 }} spacing='24px' mb='20px'>
        <Card minH='125px'>
          <Flex direction='column'>
            <Flex
              flexDirection='row'
              align='center'
              justify='center'
              w='100%'
              mb='25px'
            >
              <Stat me='auto'>
                <Flex>
                  <StatNumber fontSize='lg' color={textColor} fontWeight='bold'>
                    {userCount}
                  </StatNumber>
                </Flex>
              </Stat>
              <IconBox
                borderRadius='50%'
                as='box'
                h={"45px"}
                w={"45px"}
                bg={iconBlue}
              >
                <ProfileIcon h={"24px"} w={"24px"} color={iconBoxInside} />
              </IconBox>
            </Flex>
            <Text
              color='gray.600'
              fontSize='sm'
              fontFamily={theme.fonts.userAbout}
            >
              Total User
            </Text>
          </Flex>
        </Card>
        <Card minH='125px'>
          <Flex direction='column'>
            <Flex
              flexDirection='row'
              align='center'
              justify='center'
              w='100%'
              mb='25px'
            >
              <Stat me='auto'>
                <Flex>
                  <StatNumber fontSize='lg' color={textColor} fontWeight='bold'>
                    {questionCount}
                  </StatNumber>
                </Flex>
              </Stat>
              <IconBox
                borderRadius='50%'
                as='box'
                h={"45px"}
                w={"45px"}
                bg={iconBlue}
              >
                <IconBox
                  borderRadius='50%'
                  as='box'
                  h={"45px"}
                  w={"45px"}
                  bg={iconBlue}
                >
                  <FaQuestion h={"24px"} w={"24px"} color={iconBoxInside} />
                </IconBox>
              </IconBox>
            </Flex>
            <Text
              color='gray.600'
              fontSize='sm'
              fontFamily={theme.fonts.userAbout}
            >
              Total Questions
            </Text>
          </Flex>
        </Card>
      </SimpleGrid>
    </Flex>
  );
}
