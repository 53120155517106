// Chakra imports
import React, { useEffect, useRef, useState, useReducer } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';

import {
  Avatar,
  AvatarGroup,
  Box,
  Button,
  Flex,
  Grid,
  Icon,
  Image,
  Link,
  Switch,
  Text,
  useColorMode,
  useColorModeValue,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Input,
  Textarea,
  FormLabel,
  chakra,
  Container,
  Stack,
  Show,
  HStack,
  Select,
  FormControl,
  Divider,
  FormErrorMessage
} from "@chakra-ui/react";
// Assets


import {
  FaCube,
  FaPenFancy,
  FaPlus,
  FaArrowCircleLeft,
  FaUserCircle,
  FaInstagram,
  FaTwitter,
  FaYoutube,
  FaFacebook,
  FaUserPlus,
  FaUserCheck,
  FaEdit
} from "react-icons/fa";

import axios from 'axios';

import { notification } from 'antd';

import signInImage from "assets/img/signInImage.png";
import jwt_decode from "jwt-decode";

function Verify() {



  const { colorMode } = useColorMode();
  const bgForm = useColorModeValue("white", "navy.800");
  const titleColor = useColorModeValue("gray.700", "blue.500");
  const textColor = useColorModeValue("gray.700", "white");
  const colorIcons = useColorModeValue("gray.700", "white");
  const bgIcons = useColorModeValue("trasnparent", "navy.700");
  const bgIconsHover = useColorModeValue("gray.50", "whiteAlpha.100");
  const iconColor = useColorModeValue("blue.500", "white");
  const bgProfile = useColorModeValue("hsla(0,0%,100%,.8)", "navy.800");
  const borderProfileColor = useColorModeValue("white", "transparent");
  const emailColor = useColorModeValue("gray.400", "gray.300");

  const { id } = useParams(); //get the URL parameters

  const [hide, setHide] = useState(false);

  const server= "https://23.20.212.243:8080/api/";
  // const server= "http://ec2-34-201-174-68.compute-1.amazonaws.com:3000/api/";


  const history = useHistory();

  const [message, setMessage] = useState("");


  useEffect(() => {


    axios.post(`${server}auth/verifyEmail`, {
      "token": id,
    })
      .then((result) => {


        if (result.data.status === true) {

          sessionStorage.setItem("token", result.data.token);
          sessionStorage.setItem("server", server);

          history.push("/admin/dashboard");

        } else {
          notification.error({
            top: '10%',
            placement: 'topRight',
            message: result.data.message,
          });
        }
      })
      .catch(function (error) {
        console.log(error);

      });


  }, [])

  return (
    <>

      <Flex position='relative' mb='10px'>
        <Flex
          minH={{ md: "96vh" }}
          h={{ sm: "initial", md: "75vh", lg: "85vh" }}
          w='100%'
          maxW='1044px'
          mx='auto'
          justifyContent='space-between'
          mb='30px'
          pt={{ md: "0px" }}>
          <Flex
            w='100%'
            h='100%'
            alignItems='center'
            justifyContent='center'
            mb='60px'
            mt={{ base: "50px", md: "20px" }}>
            <Flex
              zIndex='2'
              direction='column'
              w='445px'
              background='transparent'
              borderRadius='15px'
              p='40px'
              mx={{ base: "100px" }}
              m={{ base: "20px", md: "auto" }}
              bg={bgForm}
              boxShadow={useColorModeValue(
                "0px 5px 14px rgba(0, 0, 0, 0.05)",
                "unset"
              )}>

            </Flex>
          </Flex>

          <Box
            overflowX='hidden'
            h='100%'
            w='100%'
            left='0px'
            position='absolute'
            bgImage={signInImage}>
            <Box
              w='100%'
              h='100%'
              bgSize='cover'
              bg='blue.500'
              opacity='0.8'></Box>
          </Box>

        </Flex>
      </Flex>
    </>
  );
}

export default Verify;
